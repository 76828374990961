import { isValidElement } from "react";
import moment from "moment";
import qs from "query-string";

export const isNonEmptyString = value => typeof value === "string" && value.length > 0;

export const isString = value => typeof value === "string";

export const isNumber = value => typeof value === "number";

export const isBool = value => typeof value === "boolean";

export const isArray = Array.isArray;

export const isNonEmptyArray = value => Array.isArray(value) && value.length > 0;

export const isNonEmptyMap = value => value instanceof Map && value.size > 0;

export const isFunction = value => typeof value === "function";

export const isObject = object => object && typeof object === "object";

export const isNonEmptyObject = object => isObject(object) && Object.keys(object).length > 0;

export const isObjectWithKey = (object, key) => isObject(object) && key in object;

export const stringifyJSON = value => !!value ? JSON.stringify(value) : undefined;

export const isNode = value => {
	return isObject(value) && (isValidElement(value) || value instanceof Node);
};

export const isMap = value => isObject(value) && value instanceof Map;

export const parseJSON = json => {
	const parsedData = isNonEmptyString(json)
		? JSON.parse(json)
		: undefined;

	return !!parsedData ? parsedData : undefined;
};

export const isValueUnique = (array, value, type) => {
	if (isNonEmptyString(type)) {
		switch(type) {
			case "string": {
				if (!isNonEmptyString(value)) return false;
				const lcValue = value.trim().toLowerCase();

				return !array.some(arrayValue => arrayValue.trim().toLowerCase() === lcValue);
			}
			default: return false;
		}
	}

	return false;
};

/**
 * expects plain object hash of { stringClassName: boolShouldInclude } key-value pairs
 */
export const crunchClasses = classHash => {
	const matchingKeys = [];
	const keys = isObject(classHash)
		? Object.keys(classHash)
		: [];

	keys.forEach(key => {
		const isValidClassName = isNonEmptyString(key) && classHash.hasOwnProperty(key);

		if (isValidClassName && classHash[key] === true) {
			matchingKeys.push(key);
		}
	});

	return matchingKeys.join(" ");
};

export const slugifyString = value => isNonEmptyString(value)
	? value.toLowerCase().replace(/[^a-z0-9\s-]/g, "").replace(/[\s]+/g, "-")
	: value;

export const ucFirst = value => {
	return isNonEmptyString(value)
		? value.charAt(0).toUpperCase() + value.slice(1)
		: value;
};

export const sortByAlpha = (a, b) => {
	const valueA = a.value.toUpperCase().trim();
	const valueB = b.value.toUpperCase().trim();

	return valueA.localeCompare(valueB);
};

export const alphaSortCollectionByKey = (collection, key) => {
	collection.sort((a, b) => {
		const valueA = isNonEmptyString(a[key])
			? a[key].toLowerCase()
			: "";

		const valueB = isNonEmptyString(b[key])
			? b[key].toLowerCase()
			: "";

		return valueA.localeCompare(valueB);
	});
};

export const encodeSearchTerms = searchTerms => {
	const termsArray = Array.isArray(searchTerms) ? searchTerms : [ searchTerms ];
	const termHasKeyAndVal = term => isObjectWithKey(term, "key") && isObjectWithKey(term, "val");

	return termsArray
		.filter(termHasKeyAndVal)
		.map(({ key, val }) => encodeURIComponent(`${key}:${val}`));
};

export const logMissingFactoryConstant = key => console.error("No '%s' constant available, unable to use factory action", key);

export const spUrl = url => url && !url.includes("/sp") ? `/sp/${url.replace(/^\//, "")}` : url;

// preserve the selected calendar day for displaying a day in a different timezone
export const momentToCalendarDay = (date, tz) => {
	return moment.isMoment(date) ? date.tz(tz).toDate() : date;
};

export const generateId = (() => {
	let index = 0;

	return prefix => {
		index++;

		return isNonEmptyString(prefix)
			? `${prefix}-${index}`
			: `id-${index}`;
	};
})();

export const getValueFromObjectByKeys = (obj, keys) => {
	const keyWithContent = isNonEmptyArray(keys) && !!obj
		? keys.find(key => !!obj[key])
		: undefined;

	return isNonEmptyString(keyWithContent)
		? obj[keyWithContent]
		: "";
};

export const getKeysFromColumnHeaders = columnHeaders => {
	const requestedFieldKeyMap = new Map();

	if (isNonEmptyArray(columnHeaders)) {
		columnHeaders
			.filter(({ keys }) => isNonEmptyArray(keys))
			.forEach(({ keys }) => {
				keys.forEach(key => requestedFieldKeyMap.set(key, key));
			});
	}

	return Array.from(requestedFieldKeyMap.keys());
};

export const removeDOMNode = node => node.parentNode.removeChild(node);

export const getDateFromTimestamp = timestamp => {
	return timestamp ? moment(timestamp * 1000) : moment();
};

export const getQuerystringValue = key => {
	const params = qs.parse(window.location.search);
	return key in params ? params[key] : undefined;
};

export const debounce = (callback, wait) => {
	let timeout;

	return (...args) => {
		const context = this;
		clearTimeout(timeout);
		timeout = setTimeout(() => callback.apply(context, args), wait);
	};
};

export const isValidUrl = urlString => {
	try {
		new URL(urlString);
	} catch (_) {
		return false;
	}

	return true;
};

// returns an array of child DOM nodes of a passed "element" node that may receive keyboard input,
// in the order that they appear in the DOM. If no argument is passed, the root node is the document object
// focusable HTML elements include any non-disabled a, button, input, textarea, select, details, or
// elements assigned a tabindex of >= 0
export const getKeyboardFocusableElements = (element = document) => {
	return [ ...element.querySelectorAll(
		"a,  button, input, textarea, select, details,[tabindex]:not([tabindex=\"-1\"])"
	  ) ]
		.filter(el => !el.hasAttribute("disabled"));
};

export const convertDateByTimezone = (date, timezone) => {
	var utcCutoff = moment(date).format('YYYY-MM-DD hh:mm:ss');
	return moment.tz(utcCutoff, timezone);
};