import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";

const BasePadding = ({ children, desktop, mobile }) => {
	const crunchClasses = () => {
		const desktopPadding = desktop?.disabled !== true
			? styles.desktop
			: "";
		const mobilePadding = mobile?.disabled !== true
			? styles.mobile
			: "";

		return `${desktopPadding} ${mobilePadding}`;
	};

    return (
		<div className={crunchClasses()}>
			{children}
		</div>
    );
};

BasePadding.propTypes = {
	children: PropTypes.node,
	desktop: PropTypes.shape({
		disabled: PropTypes.bool
	}),
	mobile: PropTypes.shape({
		disabled: PropTypes.bool
	})
};

export default BasePadding;