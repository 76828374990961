import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { generateId, isNonEmptyString } from "utils";

// A component that wraps a set of controls in an element with the contextual role of "toolbar"
// Keyboard navigation and ARIA property management follows https://w3c.github.io/aria-practices/#toolbar
// and uses a roving tabinidex to manage focus.  Toolbar control items are expected to have the class 
// "cayuse-toolbar-item" to be recognized by the toolbar as a tabbable control.
const ToolBar = ({ children, ariaLabel, ariaLabelledBy, ariaControls, ariaOrientation }) => {
    const BASE_TOOLBAR_ID = useMemo(() => generateId("toolbar"), []);
    const toolbarRef = useRef();

    // compile the ARIA attributes passed into the component.
    const getAriaAttributes = () => {
        // NOTE: aria-orientation for toolbars is "horizontal" by default in HTML, so only need to pass in "vertical"
        // for vertical toolbars
        const ariaProps = [ "aria-label", "aria-controls", "aria-labelledby", "aria-orientation" ];
        const ariaValues = [ ariaLabel, ariaControls, ariaLabelledBy, ariaOrientation ];

        // aria-describedby will always be present
        let baseAriaObject = { "aria-describedby": `${BASE_TOOLBAR_ID}-description` };

        ariaProps.forEach( (ariaProp, ind) => {
            if (isNonEmptyString(ariaValues[ind])) {
                baseAriaObject[ariaProp] = ariaValues[ind];
            }
        });

        return baseAriaObject;
    };

    // handle keydown events inside the toolbar and establish a roving focus when navigating with arrow keys
    const handleKeyDown = evt => {
        // returns the index of the next toolbar item in the direction of traversal. 
        // Basic logic: determine direction of toolbar button traversal, determine the upper 
        // and lower boundaries for that traversal, and return either the adjacent element
        // index or the index of the lower boundary item (for wrap around navigation)
        const getNextItemIndex =function(key, currentItem) {
            const toolbarItems = Array.from(toolbarRef.current.querySelectorAll(".cayuse-toolbar-item"));
            const currentItemIndex = toolbarItems.findIndex( item => currentItem === item );
            // ArrowDown/ArrowRight is ascending the toolbar items, ArrowUp/ArrowLeft is descending them
            const isAscending = (key === "ArrowDown") || (key === "ArrowRight");
            const lastItemIndex = toolbarItems.length - 1;

            // if the item isn't in the toolbar, something has gone horribly wrong. Let's return for a graceful exit
            if (currentItemIndex === -1) {
                return currentItemIndex;
            }
            
            const upperBoundaryIndex = isAscending ? lastItemIndex : 0;
            const lowerBoundaryIndex = isAscending ? 0 : lastItemIndex;
            const nextItemIndex = isAscending ? currentItemIndex + 1 : currentItemIndex - 1;

            return (currentItemIndex === upperBoundaryIndex) ? lowerBoundaryIndex : nextItemIndex;
        };

        // returns the DOM element for the next toolbar item to receive focus. If passed the strings "first" or
        // "last", returns the first or last toolbar item
        const getNextItem = function(nextItemIndex) {
            const toolbarItems = Array.from(toolbarRef.current.querySelectorAll(".cayuse-toolbar-item"));
            nextItemIndex = (nextItemIndex === "first") ? 0
                            : (nextItemIndex === "last") ? toolbarItems.length -1
                            : nextItemIndex;

            return toolbarItems[nextItemIndex];
        };

        // reset tab indices and move the focus to the next item
        const setNextToolBarItem = function(currentItem, nextItem) {
            currentItem.setAttribute("tabindex", "-1");
            nextItem.setAttribute("tabindex", "0");
            nextItem.focus();
        };

        const key = evt.key;
        const currentItem = evt.target;

        switch(key) {
            case "ArrowUp":
            case "ArrowDown":
            case "ArrowLeft":
            case "ArrowRight":
                evt.preventDefault();
                const nextItemIndex = getNextItemIndex(key, currentItem);

                if (nextItemIndex !== -1) {
                    setNextToolBarItem(currentItem, getNextItem(nextItemIndex));
                }
            break;

            case "Home":
                evt.preventDefault();
                setNextToolBarItem(currentItem, getNextItem("first"));
            break;

            case "End":
                evt.preventDefault();
                setNextToolBarItem(currentItem, getNextItem("last"));
            break;

            default:
                return;
        }
    };

    return (
        <div id={BASE_TOOLBAR_ID}
            ref={toolbarRef}
            role="toolbar"
            {...getAriaAttributes()}
            onKeyDown={handleKeyDown}
        >
            {children}

            <div id={`${BASE_TOOLBAR_ID}-description`} className="accessibility-text">
                <p>
                    Use the right or down arrow key to select the next toolbar item. Use the up or
                    left or up arrow key to select the previous toolbar item. Use the home or end keys
                    to select the first or last toolbar item respectively. Use tab or shift plus tab 
                    to exit the toolbar.
                </p>
            </div>
        </div>
    );
};

ToolBar.propTypes = {
    children: PropTypes.node,
    ariaLabel: PropTypes.string,
    ariaLabelledBy: PropTypes.string,
    ariaControls: PropTypes.string,
    ariaOrientation: PropTypes.string
};

export default ToolBar;