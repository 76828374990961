import store from "utils/store";
import { isNonEmptyString, isObject, logMissingFactoryConstant, proxy } from "utils";

export const actionFactory = constants => {
	const dispatch = store.dispatch;

	const setIsFetching = isNonEmptyString(constants.IS_FETCHING)
		? (isFetching, { isRefresh } = {}) => dispatch({ type: constants.IS_FETCHING, isFetching, isRefresh })
		: () => logMissingFactoryConstant("IS_FETCHING");

	const setError = isNonEmptyString(constants.SET_ERROR)
		? error => dispatch({ type: constants.SET_ERROR, error })
		: () => logMissingFactoryConstant("SET_ERROR");

	const setContent = isNonEmptyString(constants.SET_CONTENT)
		? content => dispatch({ type: constants.SET_CONTENT, content })
		: () => logMissingFactoryConstant("SET_CONTENT");

	return {
		setIsFetching,
		setError,
		setContent
	};
};

export const fetchResource = (url, parts = [], useGlobalErrorHandler = true) => {
	const requestSubresource = (resource) => part => (
		resource._links[part]?.href ?
			proxy.request(resource._links[part].href, {}, useGlobalErrorHandler)
				.catch(_ => Promise.resolve(null)) :
			Promise.resolve({})
	);

	const reducer = (parts) => (acumulator, current, index) => ({
		...acumulator,
		[parts[index]]: current
	});

	return proxy.request(url, {}, useGlobalErrorHandler).then((resource) => {
		return Promise
			.all([Promise.resolve(resource), ...(parts.map(requestSubresource(resource)))])
			.then(results => ({
				...results[0],
				self: results[0],
				...(results.slice(1).reduce(reducer(parts), {}))
			}));
	});
};

export const initialState = {
	isFetching: false,
	isRefresh: false,
	error: ""
	/* this intentionally omits "content" because not all fetchable things will need to store content */
};

export const reduceIsFetching = (state, { isFetching, isRefresh }) => ({
	...state,
	isFetching,
	isRefresh: isRefresh === true
});

export const reduceSetError = (state, { error }) => ({
	...state,
	error: error
});

export const reduceShallowContent = (state, { content }) => ({
	...state,
	content: {
		...state.content,
		...(isObject(content) ? content : {})
	}
});

export const reducers = {
	reduceIsFetching,
	reduceSetError,
	reduceShallowContent
};

export default { actionFactory, initialState, reducers };
