import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { TaskForm } from "@cayuse/shared-react.components.task-form";
import SectionHeader from "@cayuse/shared-react.components.section-header";
import BasePadding from "@bit/cayuse.react.components.base-padding";
import { notify } from "@bit/cayuse.react.components.banner-notification";
import { AlertDialog } from "@cayuse/shared-react.components.modal-dialog";

import useDictionary from "hooks/useDictionary";

export const Create = props => {
	const history = useHistory();
	const tasksDictionary = useDictionary("tasks");
	const createTaskDictionary = useDictionary("createTask");

	const [ isShowConfirmCancelModal, setShowConfirmCancelModal ] = useState(false);

	const redirectToHome = () => {
		history.push("/");
	};

	const preparePayload = payload => {
		payload.applicationHostUrl = `${window.location.protocol}//${window.location.hostname}/`;

		return payload;
	};

	const onSuccess = message => {
		notify(message, { type: "success" });
		redirectToHome();
	};

	const onCancel = ({ unsavedChanges }) => {
		if (unsavedChanges) {
			setShowConfirmCancelModal(true);
		} else {
			redirectToHome();
		}
	};

	const onAbortCancel = () => {
		setShowConfirmCancelModal(false);
	};

	return (
		<>
			<SectionHeader
				header={<h2>{createTaskDictionary.breadcrumbs.newTask ?? "New Task"}</h2>}
				breadcrumbs={[
					{
						text: createTaskDictionary.breadcrumbs.home ?? "Home",
						link: "/"
					},
					{
						text: createTaskDictionary.breadcrumbs.newTask ?? "New Task",
					},
				]}
			/>
			<BasePadding>
				<TaskForm
					onConfirm={preparePayload}
					onCancel={onCancel}
					onSuccess={onSuccess}
				/>
			</BasePadding>
			<AlertDialog
				title={tasksDictionary.unsavedChangesAlertDialog.title ?? "Leave Page?"}
				description={tasksDictionary.unsavedChangesAlertDialog.description ?? "Changes you made will not be saved."}
				isOpen={isShowConfirmCancelModal}
				onConfirm={redirectToHome}
				onCancel={onAbortCancel}
				confirmText={tasksDictionary.unsavedChangesAlertDialog.confirmText ?? "Leave page"}
			/>
		</>
	);
};
