import React from "react";
import {
    useDictionary
} from "hooks";

import {
    SHOW_MY_REQUESTS
} from "globals/constants";

import MakeARequest from "./MakeARequest";
import BasePadding from "components/BasePadding";
import VerticalSpacing from "components/VerticalSpacing";
import SubHeader from "components/SubHeader";

import styles from "./styles.css";


const WidgetMainView = () => {
    const dictionary = useDictionary("tasks").widget;
    const showMyRequests = Boolean(SHOW_MY_REQUESTS === 'true');

    return showMyRequests && (
            <div>
                <VerticalSpacing>
                    <BasePadding>
                        <div className={styles.taskWidgetMainContainer}>
                            <MakeARequest />
                        </div>
                    </BasePadding>
                </VerticalSpacing>
            </div>
    );
};

export default WidgetMainView;