import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Pagination } from "semantic-ui-react";
import { useDictionary } from "hooks/useDictionary";
import { isNonEmptyArray, isFunction } from "utils";
import Pager from "components/Pager";

import styles from "./styles.css";


const PagingControls = ({
	items,
	pageLimit,
	pageOffset,
	totalItems,
	onPageChange,
	onPageSizeChange,
	showPageSizeControl
}) => {
	const {
		showing,
		xOfYSeparator,
		items: itemsLabel,
		ariaPreviousItem,
		ariaNextItem,
		paginationDropdownAriaLabel
	} = useDictionary("pagingControls");
	const hasItems = isNonEmptyArray(items);

	const pageSizeOptions = [ 10, 25, 50, 100 ].map((option, index) => {
		return { key: index, text: option, value: option };
	});

	const totalPages = Math.ceil(totalItems / pageLimit);

	const pageBegin = Pager.pageBegin(items, pageLimit, pageOffset);
	const pageEnd = pageBegin + Pager.pageEnd(items, pageLimit, pageOffset);

	const handlePageChange = activePage => {
		if (isFunction(onPageChange)) {
			onPageChange(activePage);
		}
	};

	const handlePageSizeChange = pageSize => {
		if (isFunction(onPageSizeChange)) {
			onPageSizeChange(pageSize);
		}
	};

	const getShowingXofYLabel = () => {
		const startIndex = hasItems ? pageBegin + 1 : 0;
		const endIndex = pageEnd < totalItems ? pageEnd : totalItems;

		return totalPages > 1
			? `${showing} ${startIndex} - ${endIndex} ${xOfYSeparator} ${totalItems} ${itemsLabel}`
			: `${showing} ${totalItems} ${xOfYSeparator} ${totalItems} ${itemsLabel}`;
	};

	return hasItems
		? (
			<div className={styles.paging}>
				{showPageSizeControl !== false
					? (
						<div className={styles.pageSizeWrapper}>
							<Dropdown selection
									  compact
									  openOnFocus={false}
									  selectOnNavigation={false}
									  text={`${pageLimit} per page`}
									  options={pageSizeOptions}
									  upward={true}
									  value={pageLimit}
									  closeOnChange={true}
									  {...{ "aria-label": paginationDropdownAriaLabel || "Number of Items" }}
									  onChange={(event, {value}) => handlePageSizeChange(value)}
							/>
						</div>
					) : ""
				}

				<div className={styles.xOfYWrapper}>{getShowingXofYLabel()}</div>

				<div className={styles.pageNumberWrapper}>
					{totalPages > 1 &&
					<Pagination activePage={pageOffset + 1}
								ellipsisItem={null}
								prevItem={{
									"aria-label": ariaPreviousItem || "Previous item",
									content: <i aria-hidden="true" className="arrow left icon" />,
									disabled: pageOffset === 0
								}}
								nextItem={{
									"aria-label": ariaNextItem || "Next item",
									content: <i aria-hidden="true" className="arrow right icon" />,
									disabled: pageOffset === totalPages - 1
								}}
								firstItem={null}
								lastItem={null}

								totalPages={totalPages} // Total number of pages
								siblingRange={1}
								boundaryRange={1}
								onPageChange={(event, { activePage }) => handlePageChange(activePage)}
					/>
					}
				</div>
			</div>
		)
		: "";
};

PagingControls.propTypes = {
	items: PropTypes.array,
	onPageChange: PropTypes.func,
	onPageSizeChange: PropTypes.func,
	pageLimit: PropTypes.number,
	pageOffset: PropTypes.number,
	totalItems: PropTypes.number,
	totalPages: PropTypes.number,
	showPageSizeControl: PropTypes.bool
};

export default PagingControls;
