import React, { useEffect, useState } from "react";
import { Provider, useSelector } from "react-redux";
import moment from "moment-timezone";
//TODO the import is screwed up, figure out why setCayuseHeader has to be imported first
import { setCayuseHeader, fetchShell, setLocale } from "store/actions/shell";
import { AuthProvider, useAuth } from "@cayuse/shared-react.utils.auth";
import history from "utils/history";
import { getBrowserLanguage, setHTMLElementLangAttribute } from "utils";
import store from "utils/store";
import { setDictionary } from "store/actions/dictionary";
import { registerGetTokenSilently } from "@bit/cayuse.react.utils.http";
import { registerGetTokenSilently as cayuseRegisterGetTokenSilently } from "@cayuse/shared-react.utils.http";
import { TenantProvider } from "@cayuse/shared-react.context.tenant";
import { getTokenSilently } from "utils/proxy";

import {
    COMMON_HEADER_SCRIPT_BRANCH,
    DEFAULT_LOCALE,
    SUPPORTED_LOCALES,
    PRODUCT_KEY,
    LOGOUT_ROUTE,
    LOGOUT_REDIRECT_ROUTE, COMMON_HEADER_TARGET_BUCKET
} from "globals/constants";

import CayuseCommonHeader from "@bit/cayuse.react.components.cayuse-common-header";
import Routing from "components/Routing";
import BannerNotification from "@bit/cayuse.react.components.banner-notification";
import Pendo from "components/Pendo";

import "@cayuse/shared-react.globals.css/dist/variables.colors.css";


registerGetTokenSilently(getTokenSilently);
cayuseRegisterGetTokenSilently(getTokenSilently);

const GUEST_URL = "/guest";

setHTMLElementLangAttribute(getBrowserLanguage());

const onRedirectCallback = appState => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

const AuthApp = () => {
    const [didFetchShell, setDidFetchShell] = useState(false);
    const { loading } = useAuth();
    const {
        authenticationToken,
        customerImage,
        userId
    } = useSelector(state => state?.shell?.cayuseHeader || {});
    const tenantId = useSelector(state => state.shell?.content?.tenant?.id);
    const timezone = useSelector(state => state.shell?.content?.tenant?.timezone || "UTC");

    useEffect(() => {
        moment.tz.setDefault(timezone);
    }, [timezone]);

    const setGlobalLocaleForApp = locale => {
        moment.locale(locale);
        setLocale(locale);
    };

    useEffect(() => {
        if (!loading && !didFetchShell) {
            SUPPORTED_LOCALES.includes(navigator.language)
                ? setGlobalLocaleForApp(navigator.language)
                : setGlobalLocaleForApp(DEFAULT_LOCALE);

            const doFetch = () => fetchShell();

            doFetch()
                .catch(() => setDidFetchShell(false));

            setDidFetchShell(true);
        }
    }, [loading, didFetchShell]);

    return !loading
        ? (
            <>
                <a className="accessibility-text skip-link" href="#content-main">Skip to main content</a>
                <div className="App">
                    <CayuseCommonHeader
                        authenticationToken={authenticationToken || ""}
                        customerImage={customerImage}
                        logoutRoute={LOGOUT_ROUTE}
                        logoutRedirectTo={LOGOUT_REDIRECT_ROUTE}
                        product={PRODUCT_KEY}
                        userId={userId || ""}
                        scriptBranch={COMMON_HEADER_SCRIPT_BRANCH}
                        targetWebComponentsBucket={COMMON_HEADER_TARGET_BUCKET}
                    />
                    <Pendo />
                    <BannerNotification tenantId={tenantId} language={getBrowserLanguage()} />
                    <Routing />
                </div>
            </>
        )
        : "";
};

const App = () => {
    setDictionary(getBrowserLanguage());

    return (
        <Provider store={store}>
            <AuthProvider
                redirect_uri={window.location.origin + "/"}
                onRedirectCallback={onRedirectCallback}
                setCayuseHeader={setCayuseHeader}
                PRODUCT_KEY={PRODUCT_KEY}
                isGuest={window.location.pathname.toLowerCase() === GUEST_URL}
            >
                <TenantProvider>
                    <AuthApp />
                </TenantProvider>
            </AuthProvider>
        </Provider>
    );
};

export default App;
