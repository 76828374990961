import store from "utils/store";
import { getLanguageDictionary } from "utils/i18n";

const dispatch = store.dispatch;


const d = { // todo: move to constants
	SET_DICTIONARY: "DICTIONARY_SET"
};

export const setDictionary = language => {
	const dictionary = getLanguageDictionary(language);

	dispatch({ type: d.SET_DICTIONARY, dictionary });
};