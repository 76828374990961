import dictionaries from "globals/dictionaries";

export const getBrowserLanguage = () => {
	const language = navigator.language;

	return language.substr(0, 2);
};

export const setHTMLElementLangAttribute = language => {
	if (language) {
		document.documentElement.setAttribute("lang", language);
	};
};

export const getLanguageDictionary = language => {
	return language in dictionaries ? dictionaries[language] : dictionaries.en;
};