import { notification as constants } from "store/constants";
import store from "utils/store";

const dispatch = store.dispatch;

export const notify = opts => {
  const notification = {
    ...opts,
    id: Date.now()
  };

  dispatch({ type: constants.NOTIFY, notification });
};
