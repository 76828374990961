import { fetchResource } from "store/factories/fetchable";
import {
    ADMINISTRATION_PERSON_URL,
} from "globals/constants";

export const fetchPerson = async (personId, parts, useGlobalErrorHandler = true) => {
    const url = `${ADMINISTRATION_PERSON_URL}/${personId}`;

    const fetchedPerson = await fetchResource(url, parts, useGlobalErrorHandler);

    return fetchedPerson;
};