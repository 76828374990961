import {
	shell as constants,
	tenant as tenantConstants
} from "store/constants";
import {
	reduceIsFetching,
	reduceSetError,
	reduceShallowContent,
	initialState as initialFetchableState
} from "store/factories/fetchable";
import { PRODUCT_KEY } from "globals/constants";
import {
	getStoredShellContent,
	isObject,
	storeShellContent
} from "utils";

import {
	DEFAULT_LOCALE
} from "globals/constants";

const storedShellContent = getStoredShellContent();

const initialContent = {
	tenant: {
		tenantId: null,
	},
	user: {},
};

const initialState = {
	...initialFetchableState,
	cayuseHeader: {
		product: PRODUCT_KEY,
		host: null,
		userId: null,
		authenticationToken: null
	},
	content: {
		...(isObject(storedShellContent) ? storedShellContent : initialContent)
	},
	locale: DEFAULT_LOCALE
};

function reduceIncomingAction(state, action) {
	switch (action.type) {
		case constants.IS_FETCHING:
			return reduceIsFetching(state, action);
		case constants.SET_ERROR:
			return reduceSetError(state, action);
		case constants.SET_CONTENT:
			return reduceShallowContent(state, action); // todo: likely to be insufficient
		case constants.SET_LOCALE:
			return {
				...state,
				locale: action.locale
			};
		case constants.SET_CAYUSE_HEADER:
			return {
				...state,
				cayuseHeader: {
					...state.cayuseHeader,
					...action.cayuseHeader
				}
			};
		case constants.RESET:
			return initialState;
		// get this into reducers/tenant.js
		case tenantConstants.SET_CONTENT: {
			return ({
				...state,
				tenant: {
					...state.tenant,
					...action.tenant
				},
				content: {
					...state.content,
					tenant: {
						...state.content.tenant,
						...action.tenant
					}
				}
			});
		}
		default:
			return state;
	}
}

export function shell(state = initialState, action) {
	const updatedState = reduceIncomingAction(state, action);

	storeShellContent(updatedState.content);

	return updatedState;
}
