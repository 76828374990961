import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { crunchClasses, isNonEmptyString } from "utils";


const ToolBarButton = ({ children, className: incomingClassName, tabIndex, ...rest }) => {
	const tabIndexValue = (tabIndex === "0") ? "0" : "-1";
	const classNames = crunchClasses({
		[incomingClassName]: isNonEmptyString(incomingClassName),
		"cayuse-toolbar-item": true
	});

	return (
		<Button className={classNames} tabIndex={tabIndexValue} {...rest} >
			{children}
		</Button>
	);
};

ToolBarButton.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	tabIndex: PropTypes.string
};

export default ToolBarButton;