import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";
import { isNonEmptyString, getQuerystringValue, momentToCalendarDay, isValidUrl } from "utils";
import {
    refreshTasks
} from "store/actions/tasks";

import VerticalSpacing from "components/VerticalSpacing";
import BasePadding from "components/BasePadding";
import TaskListView from "containers/Task/List";
import RequestModal from "components/RequestModal";
import WidgetView from "../WidgetView";

import styles from "./styles.css";


const TaskMainView = () => {
    const userAccountId = useSelector(state => state.shell?.content?.user?.id) || {};
    const timezone = useSelector(state => state.shell?.content?.tenant?.timezone || "UTC");

    const [ modalVisible, setModalVisible ] = useState(false);

    const history = useHistory();
    const { search } = useLocation();

    const [ selectedTask, setSelectedTask ] = useState({});

    const [ selectedTaskDescription, setSelectedTaskDescription ] = useState("");
    const [ selectedTaskUrl, setSelectedTaskUrl ] = useState("");
    const [ selectedTaskId, setSelectedTaskId ] = useState("");
    const [ initialQueryStringTaskId, setInitialQueryStringTaskId ] = useState("");
    const [ selectedTaskAssigneeId, setSelectedTaskAssigneeId ] = useState("");
    const [ selectedTaskAssigneeName, setSelectedTaskAssigneeName ] = useState("");
    const [ selectedTaskCompletedBy, setSelectedTaskCompletedBy ] = useState("");
    const [ selectedTaskOwnerName, setSelectedTaskOwnerName ] = useState("");
    const [ selectedTaskCreateDate,setselectedTaskCreateDate ] = useState("");
    const [ selectedTaskDueDate, setSelectedTaskDueDate ] = useState(null);
    const [ selectedTaskStatus, setSelectedTaskStatus ] = useState("Open");
    const [ requestModalStatus, setRequestModalStatus ] = useState(false);

    const {
        content: {
            items: tasks,

            filters: {
                isCreatedByMeOnly,
                isAllOnly
            }
        },
    } = useSelector(state => state.tasks);

    useEffect(() => {
        const queryStringTaskId = getQuerystringValue("task");

        if (!initialQueryStringTaskId || initialQueryStringTaskId !== queryStringTaskId) {
            const preselectedTask = isNonEmptyString(queryStringTaskId)
                ? tasks.find(({ id }) => id === queryStringTaskId)
                : undefined;
            if (preselectedTask) {
                setTaskValues(preselectedTask);
                setModalVisible(true);
            }
        }
    }, [ tasks, initialQueryStringTaskId, search ]);

    const resetTask = () => {
        setSelectedTask({});

        setSelectedTaskDescription("");
        setSelectedTaskUrl("");
        setSelectedTaskId("");
        setSelectedTaskAssigneeId("");
        setSelectedTaskAssigneeName("");
        setselectedTaskCreateDate("");
        setSelectedTaskDueDate(null);
        setSelectedTaskOwnerName("");
        setSelectedTaskStatus("Open");
        setSelectedTaskCompletedBy("");
    };

    const setTaskValues = selectedTask => {
        setSelectedTask(selectedTask);

        setSelectedTaskDescription(selectedTask?.description);
        setSelectedTaskDueDate(selectedTask?.dueOnTimestamp !== null ?
            momentToCalendarDay(moment(selectedTask?.dueOnTimestamp).utc(), timezone) :
            null);
        setSelectedTaskUrl(selectedTask?.url);
        setSelectedTaskId(selectedTask.id);
        setSelectedTaskAssigneeName(selectedTask?.to);
        setSelectedTaskAssigneeId(selectedTask.assignee);
        setSelectedTaskStatus(selectedTask.status);
        setSelectedTaskOwnerName(selectedTask.from);
        setSelectedTaskCompletedBy(selectedTask.completedBy);
        setselectedTaskCreateDate(selectedTask?.createdOn !== null?
            momentToCalendarDay(moment(selectedTask?.createdOn).utc(),timezone):null);
    };

    const onModalConfirm = () => {
        setModalVisible(false);
        setRequestModalStatus(false);
        resetTask();

        refreshTasks(userAccountId, isCreatedByMeOnly, isAllOnly);
    };

    const onModalCancel = () => {
        setModalVisible(false);
        setRequestModalStatus(false);
        resetTask();
    };

    const showModalWithTask = selectedTask => {
        if (selectedTask?.category === "CAYUSE_MY_REQUEST") {
            setRequestModalStatus(true);
        } else {
            history.push(`/tasks/${selectedTask.id}/edit`);
        }
        setTaskValues(selectedTask);
        setModalVisible(true);
    };

    const onRowClick = row => {
        const selectedTask = tasks.find(t => t.id === row.id);

        navigateByTaskType(selectedTask);
    };

    const navigateByTaskType = selectedTask => {
        if (selectedTask.category === "CAYUSE_SP_AD_HOC") {
            window.location.href = `${selectedTask.url}/task/${selectedTask.id}`;
        } else if (
            selectedTask.category === "CAYUSE_AD_HOC" ||
            !isValidUrl(selectedTask.url) ||
            selectedTask.category === "CAYUSE_MY_REQUEST"
        ) {
            showModalWithTask(selectedTask);
        } else {
            window.location.href = selectedTask.url;
        }
    };

    const onNewTaskClick = () => {
        setRequestModalStatus(false);
        setModalVisible(true);
    };

    return (
        <VerticalSpacing>
            <BasePadding>
                <div id="view-main-task" className={styles.viewMainTask}>
                    <TaskListView
                        onRowClick={onRowClick}
                        onNewTaskClick={onNewTaskClick}
                    />
                    <WidgetView />
                    {requestModalStatus && (
                        <RequestModal
                            key="startNewTask"
                            visible={modalVisible}
                            afterConfirm={onModalConfirm}
                            afterCancel={onModalCancel}
                            initialOwnerId={selectedTask?.owner}
                            initialTaskName={selectedTask?.task}
                            initialDescription={selectedTaskDescription}
                            initialDueDate={selectedTaskDueDate}
                            initialUrl={selectedTaskUrl}
                            initialAssigneeId={selectedTaskAssigneeId}
                            initialAssigneeName={selectedTaskAssigneeName}
                            initialStatus={selectedTaskStatus}
                            initialTaskId={selectedTaskId}
                            initialOwnerName={selectedTaskOwnerName}
                            initialTask={selectedTask}
                        />
                    )}
                </div>
            </BasePadding>
        </VerticalSpacing>
    );
};

export default TaskMainView;
