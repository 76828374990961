import jwt_decode from "jwt-decode";
import { isNonEmptyString } from "utils";
import { getStore } from "utils/store";

const decodeJwt = (jwtString) => {
    return isNonEmptyString(jwtString)
        ? jwt_decode(jwtString)
        : {};
};

const getUserDetails = (store) => {
    const cayuseHeader = store.getState().shell?.cayuseHeader;

    return {
        username: cayuseHeader.userId,
        jti: decodeJwt(cayuseHeader.authenticationToken).jti
    };
};

export const getUserDetailsFromCayuseHeader = () => {
    return getStore()
        .then(getUserDetails);
};
