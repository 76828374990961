import { form as constants } from "store/constants";
import {
	reduceIsFetching,
	reduceSetError,
	reduceShallowContent,
	initialState as initialFetchableState
} from "store/factories/fetchable";

const initialState = {
	...initialFetchableState,
	content: {
		id: null, // id on proposal-feed-service
		modelId: null, // id on flowable
		version: null,
		formElements: [],
		formSections: [],
		rules: []
	}
};

export function form(state = initialState, action) {
	switch (action.type) {
		case constants.IS_FETCHING:
			return reduceIsFetching(state, action);
		case constants.SET_ERROR:
			return reduceSetError(state, action);
		case constants.SET_CONTENT:
			return reduceShallowContent(state, action);
		case constants.SET_RULES:
			return {
				...state,
				content: {
					...state.content,
					rules: action.rules
				}
			};
		case constants.RESET:
			return initialState;
		default:
			return state;
	}
}
