import React from "react";
import PropTypes from "prop-types";
import { Router, Switch, Redirect } from "react-router-dom";
import history from "utils/history";
import TaskMainView from "containers/Task/Main";
import { Create } from "containers/Task/Create";
import { Edit } from "containers/Task/Edit";
import PrivateRoute from "components/PrivateRoute";

const Routing = ({ children }) => (
		<Router history={history}>
			{children}
			<main id="content-main">
				<Switch>
					<PrivateRoute exact path="/" component={TaskMainView}/>
					<PrivateRoute exact path="/tasks/create" component={Create} />
					<PrivateRoute exact path="/tasks/:id/edit" component={Edit} />
					<Redirect to={"/"} />
				</Switch>
			</main>
		</Router>
);

Routing.propTypes = {
	children: PropTypes.node
};

export default Routing;
