import { useSelector } from "react-redux";

export const useCurrentUser = () => {
    const {
        content: {
            user: { id }
        }
    } = useSelector(state => state.shell);

    return id;
};

export default useCurrentUser;