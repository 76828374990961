import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "@flowable/forms";
import {
    useCache,
    useDictionary
} from "hooks";
import { isNonEmptyObject, isNonEmptyArray, findOrganizationById } from "utils";
import { FLOWABLE_CAYUSE_ENDPOINT, FLOWABLE_ACCESS_REQUEST_FOR, FLOWABLE_SUBAWARDEE, FLOWABLE_PRINCIPAL_INVESTIGATOR_FOR_MOD } from "globals/constants";
import { getDefaultForm, saveFlowableFormData, updateTaskData, fetchFlowableData } from "store/actions/defaultForm";
import { refreshTasks } from "store/actions/tasks";
import { fetchPerson } from "store/actions/people";
import Icon from "components/Icon";
import IconButton from "components/IconButton";
import SubHeader from "components/SubHeader";
import LoadingIndicator from "components/LoadingIndicator";

import styles from "./styles.css";


const MakeARequest = () => {
    const dictionary = useDictionary("tasks").widget;
    const [ defaultForm, setDefaultForm ] = useState(false);
    const [ defaultFormRender, setDefaultFormRender ] = useState(null);
    const [ myRequestPopup, setMyRequestPopup ] = useState(false);
    const [ flowableFormData, setFlowableFormData ] = useState({});
    const [ submitButtonStatus, setSubmitButtonStatus ] = useState(true);
    const [ person, setPerson ] = useState(true);
    const defaultFormData = useSelector(state => state?.defaultForm);
    const tasks = useSelector(state => state?.tasks);
    const { isFetching, isRefresh } = defaultFormData;

    useEffect(() => {
        const defaultFormContent = defaultFormData?.content?.taskDefaultForm;
        setDefaultFormRender({ rows: defaultFormContent?.rows });
        if(defaultFormContent?.rows?.length > 0) {
            setDefaultForm(true);
            setFlowableFormData({});
        }
    }, [ defaultFormData?.content ]);

    useEffect(() => {
        if (isNonEmptyObject(flowableFormData) && flowableFormData?.requestType !== null && defaultForm) {
            setSubmitButtonStatus(false);
        } else {
            setSubmitButtonStatus(true);
        }
    }, [ flowableFormData , defaultForm]);

    const clickHandle = () => {
        const flowAbleFormData = getDefaultForm();
    };

    const handleFormDataChange = (formData) => {
      if (formData?.requestType !== flowableFormData?.requestType) {
          setFlowableFormData({requestType: formData?.requestType});     
      } else {
          setFlowableFormData(formData);
      }
    };

    const getFullNames = persons => {
        if (isNonEmptyArray(persons)) {
            return Promise.all(persons.map(personId => {
                return fetchPerson(personId, [], false).then(person => {
                    return person?.fullName;
                });
            })).then(personNames => {
                return personNames;
            });
        }
    }

    const handleCancel = () => {
        setDefaultForm(false);
        setDefaultFormRender(null);
    };

    const getOrganizationName = organizations => {
        if (isNonEmptyArray(organizations)) {
            return Promise.all(organizations.map(organizationId => {
                return findOrganizationById(organizationId).then(organization => {
                    return organization.name;
                });
            }));
        }
    };

    const handleSubmit = async () => {
        try {
            let finalFlowableData = {};
            if (flowableFormData.hasOwnProperty(FLOWABLE_ACCESS_REQUEST_FOR)) {
                flowableFormData[FLOWABLE_ACCESS_REQUEST_FOR] = await getFullNames(flowableFormData[FLOWABLE_ACCESS_REQUEST_FOR]);
            } else if (flowableFormData.hasOwnProperty(FLOWABLE_PRINCIPAL_INVESTIGATOR_FOR_MOD)) {
                flowableFormData[FLOWABLE_PRINCIPAL_INVESTIGATOR_FOR_MOD] = await getFullNames(flowableFormData[FLOWABLE_PRINCIPAL_INVESTIGATOR_FOR_MOD]);
            } else if (flowableFormData.hasOwnProperty(FLOWABLE_SUBAWARDEE)) {
                flowableFormData[FLOWABLE_SUBAWARDEE] = await getOrganizationName(flowableFormData[FLOWABLE_SUBAWARDEE]);
            }
            Object.keys(flowableFormData).map(property => {
                const matchRow = defaultFormRender?.rows?.find(rows => rows?.cols?.[0]?.value.slice(2, -2) === property)?.cols?.[0];
                Object.assign(finalFlowableData, { [matchRow?.label]: flowableFormData?.[property] });

                return matchRow?.label;
            });
            await saveFlowableFormData(finalFlowableData);
            await updateTaskData(finalFlowableData);
            setMyRequestPopup(true);
            setTimeout(() => {
                refreshTasks(defaultFormData?.content?.taskDetail?.assignee, tasks?.content?.filters?.isCreatedByMeOnly, tasks?.content?.filters?.isAllOnly);
            }, 1000);
            setTimeout(() => {
                setMyRequestPopup(false);
            }, 5000);
            setDefaultForm(false);
        } catch (error) {
            console.log("Error: ", error);
        }
    };

    const handlePopupClose = () => {
        setMyRequestPopup(false);
    };

    const additionalData = {
        endpoints: {
            cayuse: `${FLOWABLE_CAYUSE_ENDPOINT}`
        }
    };

    return (
        <>
            <div className={styles.taskWidgetContainer}>
                <div>
                    { myRequestPopup && <div onClick={handlePopupClose} className={styles.customPopup}>
                        <Icon name="close" className={styles.popupIconColor} />
                        {dictionary?.APIMessage?.success || "Your request was submitted."}
                    </div> }
                    <div className={styles.widgetHeader}>
                        <SubHeader headerLevel="2" className={styles.proposalListOptions}>
                            {dictionary.Make_Request || "Make a Request"}
                        </SubHeader>
                    </div>
                    <div className={styles.widgetContent}>
                        { defaultForm ? 
                            <div className={styles.taskModalStatusDropdown}>
                                <Form
                                    config={defaultFormRender}
                                    onChange={handleFormDataChange}
                                    fetch={fetchFlowableData}
                                    payload={flowableFormData}
                                    className={styles.inputClass}
                                    additionalData={additionalData}
                                />
                            </div> :
                            <>
                            { isFetching || isRefresh ? <div className={styles.loaderDiv}>
                                <LoadingIndicator />
                            </div> :
                            <div className={styles.requestBtnmainDiv}>
                                <div className={styles.requestMainBtn}>
                                    <IconButton
                                        className={styles.formBtn}
                                        labelPosition="left"
                                        primary
                                        onClick={e => clickHandle(e)}
                                    >
                                        <div>{dictionary.Create_Request || "Create Request"}</div>
                                        <div className={styles.btnText}>{dictionary.Click_To_Start || "Click to start"}</div>
                                    </IconButton>
                                </div> 
                            </div> }
                            </>
                            }
                    </div>
                    <div className={styles.widgetButton}>
                        <IconButton
                            labelPosition="left"
                            secondary
                            className={styles.widgetCancelButton}
                            onClick={handleCancel}
                            disabled={!defaultForm}
                        >
                            {dictionary.makeARequestActionButtonLables?.cancel || "Cancel"}
                        </IconButton>
                        <IconButton
                            labelPosition="left"
                            primary
                            disabled={submitButtonStatus}
                            onClick={handleSubmit}
                        >
                            {dictionary.makeARequestActionButtonLables?.submit || "Submit"}
                        </IconButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MakeARequest;