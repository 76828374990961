import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";


const VerticalSpacing = ({ children }) => (
    <div data-component="VerticalSpacing" className={styles.baseMargin}>
      {children}
    </div>
);

VerticalSpacing.propTypes = {
  children: PropTypes.node
};

export default VerticalSpacing;
