import { useMemo } from "react";
import { useSelector } from "react-redux";
import { isFunction } from "utils";
import Pager from "components/Pager";

export const usePagination = (key, setPaging) => {
	if (!isFunction(setPaging)) {
		throw Error("A 'setPaging' callback must be provided to usePagination");
	}

	const { pageLimit, pageNumber } = useSelector(state => {
		return key in state
			? state[key]?.content?.paging || {}
			: {};
	});

	const pageOffset = useMemo(() => Pager.pageOffsetByPageNumber(pageNumber), [ pageNumber ]);

	const setPageNumber = incomingPageNumber => {
		if (isFunction(setPaging)) {
			setPaging({ pageNumber: incomingPageNumber });
		}
	};

	const setPageLimit = incomingPageLimit => {
		setPaging({ pageLimit: incomingPageLimit, pageNumber: 1 });
	};

	return {
		pageLimit,
		pageOffset,
		pageNumber,
		setPageLimit,
		setPageNumber
	};
};

export default usePagination;