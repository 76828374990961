import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    findTasks,
    setPaging,
    setTaskSearchBar,
    setFilters
} from "store/actions/tasks";
import {
    parseJSON,
    isNonEmptyString
} from "utils";
import {
    useDictionary,
    usePagination
} from "hooks";
import BasePadding from "components/BasePadding";
import VerticalSpacing from "components/VerticalSpacing";
import { Button } from "semantic-ui-react";
import IconButton from "components/IconButton";
import SubHeader from "components/SubHeader";
import ToolBar from "components/ToolBar";
import ToolBarButton from "components/ToolBarButton";

import styles from "./styles.css";

import FeedTable from "components/FeedTable";

const TASK_LIST_ID = "view-list-task";


const TaskListView = ({ onRowClick }) => {
    const history = useHistory();

    const dictionary = useDictionary("tasks");
    const { pageLimit, pageOffset, setPageLimit, setPageNumber } = usePagination("tasks", setPaging);

    const {
        content: {
            items:rows,
            totalItems,
            // searchItems,
            search,
            sort,
            filters: {
                isCreatedByMeOnly,
                isAllOnly
            }
        },
        isFetching: isLoading,
        isRefresh
    } = useSelector(state => state.tasks);

    const userAccountId = useSelector(state => state.shell?.content?.user?.id) || {};

    const activeColumns = [
        { keys: [ "task" ], columnHeader: "Task", slug: "task", sortKey: "name" },
        { keys: [ "task-type" ], columnHeader: "Task Type", slug: "task-type" },
        { keys: [ "fromDisplay" ], columnHeader: "From", slug: "from" },
        { keys: [ "toDisplay" ], columnHeader: "Assigned To", slug: "to" },
        { keys: [ "createdOn" ], columnHeader: "Created", slug: "createdOn", sortKey: "createTime" },
        { keys: [ "lastUpdated" ], columnHeader: "Last Activity", slug: "lastUpdated" },
        { keys: [ "dueOn" ], columnHeader: "Due", slug: "dueOn", sortKey: "dueDate" },
        { keys: [ "status" ], columnHeader: "Status", slug: "status" }
    ];

    const onPageChange = pageNumber => setPageNumber(pageNumber);

    const onPageLimitChange = pageLimit => setPageLimit(pageLimit);

    // const onSearchChange = useCallback(searchTerms => {
    //     const search = parseJSON(searchTerms);

    //     setTaskSearchBar(search);
    // }, []);

    useEffect(() => {
        if(isNonEmptyString(userAccountId)) {
            findTasks({
                userAccountId,
                pageLimit,
                pageOffset,
                searchTerms: [ { ...search } ],
                createdByMe: isCreatedByMeOnly,
                showAll: isAllOnly
            });
        }
    }, [ pageLimit, pageOffset,isCreatedByMeOnly, isAllOnly, search, userAccountId, sort ]);

    const onCreatedByMeClicked = assignValue => {
        setPageNumber(1);
        setFilters({ isCreatedByMeOnly: assignValue });
    };


    const onOpenOnlyClicked = assignValue => {
        setPageNumber(1);
        setFilters({ isAllOnly: assignValue });
    };

    const onNewTaskClick = () => {
        history.push("/tasks/create");
    };

    return (
        <VerticalSpacing>
            <BasePadding>
                <div id={TASK_LIST_ID} className={styles.taskListContainer}>
                    <div className={styles.taskListTop}>
                        <div className={`ui top attached primary segment ${styles.taskListHeaderContainer}`}>
                            <div className={styles.taskListHeaderSpacer} />
                            <SubHeader headerLevel="2" className={styles.taskListHeader}>
                                My Tasks
                            </SubHeader>
                            <div className={styles.taskListNewIcon}>
                                <IconButton
                                    icon="plus"
                                    labelPosition="left"
                                    primary
                                    onClick={onNewTaskClick}
                                >
                                    {dictionary.newTask || "New Task"}
                                </IconButton>
                            </div>
                        </div>
                        <div className="ui attached tertiary segment">
                            <div className={styles.proposalListOptions}>
                                <div className={`ui icon input ${styles.proposalListSearch}`}>
                                    {/*<SearchBar*/}
                                    {/*options={searchItems}*/}
                                    {/*onChange={(event, { value }) => onSearchChange(value)}*/}
                                    {/*value={isObject(search) ? stringifyJSON({ ...search }) : search}*/}
                                    {/*/>*/}
                                </div>
                                <ToolBar
                                    ariaLabel={dictionary.filterButtonGroupAriaLabel || "Task List Filters"}
                                    ariaControls={TASK_LIST_ID}
                                >
                                    <Button.Group style={{ paddingRight: "40px" }}>
                                        <ToolBarButton tabIndex="0" color={`${isCreatedByMeOnly ? "white" : "blue"}`}
                                                       onClick={() => onCreatedByMeClicked(false)}
                                        >Assigned to Me</ToolBarButton>
                                        <ToolBarButton color={`${isCreatedByMeOnly ? "blue" : "white"}`}
                                                       onClick={() => onCreatedByMeClicked(true)}
                                                       style={{ margin: "0px" }}
                                        >Created by Me</ToolBarButton>
                                    </Button.Group>

                                    <Button.Group>
                                        <ToolBarButton color={`${isAllOnly ? "white" : "blue"}`}
                                                       onClick={() => onOpenOnlyClicked(false)}
                                        >Open</ToolBarButton>
                                        <ToolBarButton color={`${isAllOnly ? "blue" : "white"}`}
                                                       onClick={() => onOpenOnlyClicked(true)}
                                                       style={{ margin: "0px" }}
                                        >All</ToolBarButton>
                                    </Button.Group>
                                </ToolBar>
                            </div>
                        </div>
                    </div>
                    <div>
                        <FeedTable isLoading={isLoading}
                                   isRefresh={isRefresh}
                                   headers={activeColumns}
                                   rows={rows}
                                   totalItems={totalItems}
                                   pageLimit={pageLimit}
                                   pageOffset={pageOffset}
                                   onPageChange={onPageChange}
                                   onPageLimitChange={onPageLimitChange}
                                   noResultsLabel={dictionary.noResults || "No Saved Tasks"}
                                   isSelectable={true}
                                   onRowClick={onRowClick}
                                   sort={sort}
                        />
                    </div>
                </div>
            </BasePadding>
        </VerticalSpacing>

    );
};

export default TaskListView;
