import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import { setSort } from "store/actions/tasks";
import { SELECT_ALL_KEY } from "globals/constants";
import useDictionary from "hooks/useDictionary";
import {
	isNonEmptyArray,
	isFunction,
	generateId,
	isNonEmptyString,
	isBool,
	getValueFromObjectByKeys
} from "utils";
import LoadingIndicator from "components/LoadingIndicator";
import PagingControls from "components/PagingControls";
import ScreenReaderLabel from "components/ScreenReaderLabel";

import styles from "./styles.css";
import CovertButton from "../CovertButton";


/**
 * The headers prop should be an array of key:label pairs, [{ key: "columnKey", label: "Display Value" }]
 * The "rows" prop should be an array of row values, [{ col1: "Display 1", col2: "Display 2", _isSelected: bool }]
 * 		- only columns with keys found in the headers array will be rendered
 * If using selectable rows, your row data should include an _isSelected key indicating the row's state
 * If pageLimit is boolean literal false, the paging controls will be omitted
 */
const FeedTable = ({
	headers,
	rows,
	totalItems,
	onPageChange,
	onPageLimitChange,
	onRowClick,
	pageLimit,
	pageOffset,
	isLoading,
	isRefresh,
	isSelectable,
	isSelectAll,
	accessibilityLabel,
	noResultsLabel,
	sort
}) => {
	const { table: dictionary } = useDictionary("common");
	const hasRows = isNonEmptyArray(rows);
	const hasHeaders = isNonEmptyArray(headers);
	const numColumns = hasHeaders ? headers.length + (isSelectable ? 1 : 0) : 0;

	const columnKeys = useMemo(() => {
		return hasHeaders
			? headers.map(({ slug, keys }) => ({ slug, keys }))
			: [];
	}, [ headers, hasHeaders ]);

	const getScreenReaderLabel = row => {
		const useColumnValue = isNonEmptyString((accessibilityLabel)) && accessibilityLabel in row;
		let label = "";

		if (useColumnValue) {
			label = row[accessibilityLabel];
		}

		if (isFunction(accessibilityLabel)) {
			label = accessibilityLabel(row);
		}

		return `${dictionary.selectRow || "Select row"} ${label}`;
	};

	const handleClick = (row) => {
		if (isFunction(onRowClick)) {
			onRowClick(row);
		}
	};

	return isLoading && !isRefresh
	? <LoadingIndicator />
	: (
		<div>
			<div className={styles.feedTableWrapper}>
				<Table className={styles.feedTable} selectable sortable>
					<colgroup>
						<col className={styles.taskNameCol}/>
					</colgroup>
					<Table.Header>
						<Table.Row>

							{hasHeaders && headers.map(({ slug, columnHeader, sortKey }) => {
								return (
									<Table.HeaderCell
										className={isNonEmptyString(sortKey) ? `sortable` : styles.unsortable}
										key={`header-${slug}`}
										sorted={sortKey === sort?.column ? sort?.direction : null}
										onClick={() => isNonEmptyString(sortKey) ? setSort(sortKey) : ""}
									>
										{columnHeader}
									</Table.HeaderCell>
								);
							})}
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{hasRows
							? (
								rows.map((row, rowIndex) => (
									<Table.Row key={`row-${rowIndex}`} onClick={() => handleClick(row)}>
										{
											columnKeys.map(({ keys }, colIndex) => (
												<Table.Cell key={`row-${colIndex}`}>
													<div className={styles.tdFlex}>
														<CovertButton className={styles.feedTableCovertButton}>
															{getValueFromObjectByKeys(row, keys)}
														</CovertButton>
													</div>
												</Table.Cell>
											))
										}
									</Table.Row>
								))
							)
							: (
								<Table.Row>
									<Table.Cell colSpan={numColumns}>
										<div className={styles.noResults}>
											<span>
												{noResultsLabel || dictionary.noResults || "No Results"}
											</span>
										</div>
									</Table.Cell>
								</Table.Row>
							)
						}
					</Table.Body>
				</Table>
			</div>
			{pageLimit !== false && hasRows &&
			<div className={styles.pagingControls}>
				<PagingControls items={rows}
								totalItems={totalItems}
								pageLimit={pageLimit}
								pageOffset={pageOffset}
								onPageChange={onPageChange}
								onPageSizeChange={onPageLimitChange}
				/>
			</div>
			}
		</div>
	);
};

FeedTable.propTypes = {
	headers: PropTypes.arrayOf(
		PropTypes.shape({
			keys: PropTypes.arrayOf(PropTypes.string),
			slug: PropTypes.string,
			columnHeader: PropTypes.string
		}
	)),
	rows: PropTypes.arrayOf(PropTypes.shape({
		key: PropTypes.string,
		content: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.node
		])
	})),
	totalItems: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	accessibilityLabel: PropTypes.oneOfType([ PropTypes.string, PropTypes.func ]),
	pageLimit: PropTypes.oneOfType([ PropTypes.number, PropTypes.string, PropTypes.bool ]),
	pageOffset: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
	onPageChange: PropTypes.func,
	onPageLimitChange: PropTypes.func,
	isLoading: PropTypes.bool,
	isSelectable: PropTypes.bool,
	isSelectAll: PropTypes.bool
};

export default FeedTable;
