import {
	ADMINISTRATION_AD_HOC_TEAM_URL,
	ADMINISTRATION_ORGANIZATION_URL,
	ADMINISTRATION_PERSON_URL,
	ADMINISTRATION_UNIT_URL,
} from "globals/constants";
import { executeRequest } from "utils/proxy";
import {
	isNonEmptyArray,
	isObject,
	getFieldRestQueryUrl
} from "utils";

const INT_PAGE_LIMIT = parseInt(2000);
const INT_PAGE_OFFSET = parseInt(0);
const SORT_DEFAULT_NAME_DESCENDING = "name%2CDESC";

export const findOrganizations = () => {
	return executeRequest(`${ADMINISTRATION_ORGANIZATION_URL}?page=${INT_PAGE_OFFSET}&size=${INT_PAGE_LIMIT}&sort=${SORT_DEFAULT_NAME_DESCENDING}`)
		.then(response => {
			return isNonEmptyArray(response?._embedded?.organizations)
				? response?._embedded?.organizations
				: [];
		})
		.catch(e => {
			return [];
		});
};

export const findOrganizationById = id => {
	return executeRequest(`${ADMINISTRATION_ORGANIZATION_URL}/${id}`)
		.then(response => {
			return isObject(response)
				? response
				: null;
		})
		.catch(e => {
			return null;
		});
};

export const findUnits = () => {
	return executeRequest(`${ADMINISTRATION_UNIT_URL}?page=${INT_PAGE_OFFSET}&size=${INT_PAGE_LIMIT}&sort=${SORT_DEFAULT_NAME_DESCENDING}`)
		.then(response => {
			return isNonEmptyArray(response?._embedded?.units)
				? response?._embedded?.units
				: [];
		})
		.catch(e => {
			return [];
		});
};

export const findUnitById = id => {
	return executeRequest(`${ADMINISTRATION_UNIT_URL}/${id}`)
		.then(response => {
			return isObject(response)
				? response
				: null;
		})
		.catch(e => {
			return null;
		});
};

export const findTeams = () => {
	return executeRequest(`${ADMINISTRATION_AD_HOC_TEAM_URL}?page=${INT_PAGE_OFFSET}&size=${INT_PAGE_LIMIT}&sort=${SORT_DEFAULT_NAME_DESCENDING}`)
		.then(response => {
			return isNonEmptyArray(response?._embedded?.adhocteams)
				? response?._embedded?.adhocteams
				: [];
		})
		.catch(e => {
			return [];
		});
};

export const findTeamById = id => {
	return executeRequest(`${ADMINISTRATION_AD_HOC_TEAM_URL}/${id}`)
		.then(response => {
			return isObject(response)
					? response
					: null;
		})
		.catch(e => {
			return null;
		});
};

export const findPeople = () => {
	return executeRequest(`${ADMINISTRATION_PERSON_URL}?page=${INT_PAGE_OFFSET}&size=${INT_PAGE_LIMIT}&sort=firstName%2CDESC`)
		.then(response => {
			return isNonEmptyArray(response?._embedded?.persons)
				? response?._embedded?.persons
				: [];
		})
		.catch(e => {
			return [];
		});
};

export const findPersonById = id => {
	return executeRequest(`${ADMINISTRATION_PERSON_URL}/${id}`)
		.then(response => {
			return isObject(response)
				? response
				: null;
		})
		.catch(e => {
			return null;
		});
};

export const findPicklistOptionsByFieldId = (field) => {

	if (field.dataSource) {

		if (field.dataSource.type === "Static") {

			return new Promise(resolve => {
				resolve(field.dataSource.staticValues);
			})
				.then(data => {
					return {
						options: data.map(optionValue => {
							return {
								id: optionValue,
								label: optionValue
							};
						})
					};
				});

		} else if (field.dataSource.type === "Rest") {

			const queryUrl = getFieldRestQueryUrl(field);

			return executeRequest(queryUrl)
				.then(data => {

					if (!isObject(data)) {
						return [];
					}

					return {

						options: data.map(searchValue => {
							return {
								id: searchValue.id,
								label: searchValue.name // TODO disposition based on dataSource "restFormatItem"? defer to Jon
							};
						})

					}

				})
				.catch(e => {
					return null;
				});

		}

	} else {

		console.warn("unrecognized dataSource provided in form for fieldId: ", field.id);

		return new Promise(resolve => {
			setTimeout(() => resolve([]), 500);
		})
			.then(options => {
				return {
					options: options
				};
			});

	}

};

export const findPicklistOptionByFieldIdAndOptionId = (field, targetId) => {

	return findPicklistOptionsByFieldId(field) // TODO weird data-binding issue that poisons state? defer to Jon
		.then(data => {
			return data.options.find(option => option.id === targetId);
		});
};
