import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isNonEmptyString } from "utils";
import { PENDO_PRODUCT_NAME } from "globals/constants";
import pendoSource from "./pendo.raw.js";


const Pendo = () => {
	const [ isPendoReady, setIsPendoReady ] = useState(!!window.pendo);

	const {
		cayuseHeader: {
			userId
		},
		content: {
			user:{email},
			tenant: {
				hostname
			}
		}
	} = useSelector(state => state.shell || {});

	useEffect(() => {
		const script = document.createElement("script");
		script.src = pendoSource;
		script.async = true;
		document.head.appendChild(script);

		const scriptZendesk = document.createElement("script");
		scriptZendesk.id = `ze-snippet`;
		scriptZendesk.src = `https://static.zdassets.com/ekr/snippet.js?key=bca4b06c-ddbb-44ff-b339-599262dfba01`;
		scriptZendesk.async = true;
		document.head.appendChild(scriptZendesk);

		const interval = setInterval(() => {
			if (!!window.pendo) {
				setIsPendoReady(true);
				clearInterval(interval);
			}
		}, 10);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		const hasUserId = isNonEmptyString(userId);
		const hasHostname = isNonEmptyString(hostname);

		if (isPendoReady && hasUserId && hasHostname) {
			const payload = {
				visitor: {
					id: userId
				},
				account: {
					id: hostname,
					product: PENDO_PRODUCT_NAME
				}
			};

			const hasEmail = isNonEmptyString(email);

			if(hasEmail){
				payload.visitor.email = email;
			}

			try {
				window.pendo.initialize(payload);
			} catch (error) {
				console.error("Error initializing Pendo", error);
			}
		}
	}, [ userId, hostname, isPendoReady ]);

	return null;
};

export default Pendo;