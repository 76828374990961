import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import useDictionary from "hooks/useDictionary";

import {
	getTaskVariables,
	editTask
} from "store/actions/tasks";

import {
	isNonEmptyString,
	isFunction,
	isValidUrl,
	momentToCalendarDay,
	findUnitById,
	isNonEmptyObject,
	isString,
	convertDateByTimezone
} from "utils";
import { useCurrentUser } from "hooks";
import { notify } from "@bit/cayuse.react.components.banner-notification";
import PersonFinder from '@bit/cayuse.react.components.person-finder';
import { Form, Modal as SemanticModal, Tab, Popup } from "semantic-ui-react";
import Button from "@bit/cayuse.react.components.button";
import ButtonGroup from "@bit/cayuse.react.components.button-group";
import TextArea from "@bit/cayuse.react.components.form.inputs.text-area";
import { getDefaultForm } from "store/actions/defaultForm";
import DateSelector from "components/DateSelector";
import Modal from "components/Modal";
import ConfirmationModal from "components/ConfirmationModal";
import { MAX_WIDTH, MID_WIDTH, MIN_WIDTH } from "globals/constants";

import styles from "./styles.css";


const RequestModal = ({
	visible,
	afterConfirm,
	afterCancel,
	initialTaskName,
	initialDescription,
	initialDueDate,
	initialAssigneeId,
	initialAssigneeName,
	initialUrl,
	initialStatus,
	initialTaskId,
	initialAssigneeEmail,
	initialOwnerName,
	initialOwnerId,
	initialTask: {
		completedBy: initialTaskCompletedBy,
		lastUpdated: initialTaskLastUpdated
	}
}) => {
	const currentUser = useCurrentUser();
	const isTaskOwner = currentUser === initialOwnerId;

	const dictionary = useDictionary("tasks");
	const todaysDate = moment().startOf("day").toDate();

	const [isConfirmCancelOpen, setIsConfirmCancelOpen] = useState(false);
	const [isInactiveUserSelected, setIsInactiveUserSelected] = useState(false);
	const [isUrlValid, setIsUrlValid] = useState(true);
	const [isLoading, setIsloading] = useState(false);

	const [confirmDisabled, setConfirmDisabled] = useState(false);
	const [disabledReason, setDisabledReason] = useState(null);
	const [inputDisabled, setInputDisabled] = useState(false);

	const [dueDate, setDueDate] = useState(null);
	const [taskDescription, setTaskDescription] = useState(initialDescription);
	const [url, setUrl] = useState("");
	const [status, setStatus] = useState("Open");

	const [selectedAssigneeId, setSelectedAssigneeId] = useState("");
	const [selectedAssigneeName, setSelectedAssigneeName] = useState("");
	const [selectedAssigneeEmail, setSelectedAssigneeEmail] = useState("");

	const [requestFormDisabled, setRequestFormDisabled] = useState(true);
	const [requestTabData, setRequestTabData] = useState([]);
	const [initialRequestData, setInitialRequestDate] = useState([]);
	const [requestDataChange, setRequestDataChange] = useState(true);
	const [personUnit, setPersonUnit] = useState();

	// TODO: Determine proper behavior for when logged in user has no Person profile
	const person = useSelector(state => state.shell?.content?.person || {});

	// TODO: Better error handling?
	const timezone = useSelector(state => state.shell?.content?.tenant?.timezone || 'UTC');
	const locale = useSelector(state => state.shell?.locale);
	const taskVariable = useSelector(state => state.tasks.content?.taskVariable);
	const defaultForm = useSelector(state => state.defaultForm?.content?.taskDefaultForm);
	const dateFormat = moment.localeData(locale).longDateFormat('L') || "MM/DD/YYYY";
	const datePickerFormat = dateFormat.toLowerCase().replace("mm", "MM");

	const taskStatusOptions = [
		{
			key: 1, text: dictionary.open || "Open", value: "Open"
		},
		{
			key: 2, text: dictionary.closed || "Closed", value: "Closed"
		}
	];

	const reset = () => {
		setDueDate(null);
		setTaskDescription("");
		setUrl("");
		setStatus("Open");
		setSelectedAssigneeName("");
		setSelectedAssigneeId("");
		setSelectedAssigneeEmail("");
		setInputDisabled(false);
		setConfirmDisabled(true);
		setRequestDataChange(true);
		setIsInactiveUserSelected(false);
		setIsUrlValid(true);
	};

	useEffect(() => {
		setTaskDescription(initialDescription);

		setDueDate(initialDueDate ? moment(initialDueDate) : null);
		setUrl(initialUrl);
		setSelectedAssigneeId(initialAssigneeId);
		setSelectedAssigneeName(initialAssigneeName);
		setSelectedAssigneeEmail(initialAssigneeEmail);
		setStatus(initialStatus);

		if (isNonEmptyString(initialTaskId) && initialStatus === "Closed")
			setInputDisabled(true);
	}, [initialDescription, initialDueDate, initialUrl, initialAssigneeId, initialAssigneeName, initialTaskId, initialStatus, initialAssigneeEmail]);

	const unsavedChanges = useMemo(() => {
		const taskChanged = taskDescription !== initialDescription;
		const dateChanged = dueDate !== initialDueDate;
		const userChanged = selectedAssigneeId !== initialAssigneeId;
		const statusChanged = status !== initialStatus;
		const urlChanged = url !== initialUrl;

		return taskChanged || dateChanged || userChanged || statusChanged || urlChanged;
	}, [taskDescription, initialDescription, dueDate, initialDueDate, selectedAssigneeId, initialAssigneeId, status, initialStatus, url, initialUrl]);

	const changeRequestTypeData = e => {
		const inputName = e.target.name;
		const inputValue = e.target.value;
		const updatedRequestData = { ...requestTabData, [inputName]: inputValue };
		const initialDataSort = Object.values(initialRequestData).sort();
		const updatedRequestDataSort = Object.values(updatedRequestData).sort();
		const requestDataStatus = JSON.stringify(initialDataSort) === JSON.stringify(updatedRequestDataSort);
		setRequestDataChange(requestDataStatus);
		setRequestTabData(updatedRequestData);
	};

	const onCancel = () => {
		unsavedChanges
			? setIsConfirmCancelOpen(true)
			: onConfirmCancel();
	};

	const onConfirmCancel = () => {
		setIsConfirmCancelOpen(false);

		reset();
		if (isFunction(afterCancel)) {
			afterCancel();
		}
	};

	const onAbortCancel = () => {
		setIsConfirmCancelOpen(false);
	};

	const onClose = () => {
		reset();
		if (isFunction(afterConfirm)) {
			afterConfirm();
		}
	};

	const onConfirm = () => {
		if (url && isNonEmptyString(url)) {
			if (isValidUrl(url)) {
				setIsUrlValid(true);
			} else {
				setIsUrlValid(false);
				return;
			}
		} else {
			setIsUrlValid(true);
		}

		const taskPayload = {
			taskId: initialTaskId,
			name: initialTaskName,
			dueOn: dueDate ?? convertDateByTimezone(dueDate, timezone),
			description: taskDescription,
			assigneeId: selectedAssigneeId,
			assigneeName: selectedAssigneeName,
			assigneeEmail: selectedAssigneeEmail,
			lastUpdatedBy: person?.fullName,
			status,
			url,
			category: "CAYUSE_MY_REQUEST",
			applicationHostUrl: window.location.href,
			completedBy: status === "Closed" ? person?.fullName : undefined,
			timezone: timezone
		};
		setIsloading(true);

		return editTask(initialTaskId, taskPayload, requestTabData)
			.catch((error) => {
				const errorMessage = error.message || "Something went wrong";
				notify(errorMessage, { type: "error" });
			})
			.finally(() => {
				onClose();
				setIsloading(false);
			});
	};

	// construct a new moment object in UTC using only the date part provided by the incoming date
	const startOfSelectedDayUTC = date => {
		return momentToCalendarDay(date, "UTC");
	};

	const onDateChange = date => {
		var timeZoneDate = convertDateByTimezone(date, timezone);
		setDueDate(timeZoneDate);
	};

	const onStatusChange = (event, { value }) => {
		setStatus(value);
	};

	useEffect(() => {
		const assigneeIdSet = selectedAssigneeId && isNonEmptyString(selectedAssigneeId);
		const assigneeNameSet = selectedAssigneeName && isNonEmptyString(selectedAssigneeName);

		const isConfirmDisabled = !assigneeIdSet ||
			!assigneeNameSet ||
			inputDisabled ||
			!unsavedChanges;
		setConfirmDisabled(isConfirmDisabled);
	}, [selectedAssigneeId, selectedAssigneeName, taskDescription, inputDisabled, unsavedChanges]);

	useEffect(() => {
		if (initialTaskId) {
			getTaskVariables(initialTaskId);
			const unitId = person?.affiliations?.[0]?.unitId;
			if (unitId) {
				findUnitById(unitId).then(data => {
					setPersonUnit(data);
				});
			}
			if (!isNonEmptyObject(defaultForm)) {
				getDefaultForm();
			}
		}
	}, [initialTaskId]);

	useEffect(() => {
		const defaultFormProp = dictionary?.defaultForm;
		let finalOrderFlowableData = {};
		const extractFormProperty = ["lastUpdatedBy", "flowablePayloadTime", "accessRequestedTo", "flowableDatabasePayload", "url", "lastUpdated", "task", "assignee", "category", "completedBy", "status", "flowablePayloadHash", "assignedFrom"];
		const allFormProperty = Object.keys(taskVariable);
		const requestTabArray = allFormProperty?.map(allProp => {
			const requestProperty = !extractFormProperty.some(extractProp => extractProp === allProp);

			return requestProperty ? { [allProp]: taskVariable[allProp] } : null;
		}).filter(prop => prop !== null ? true : false);
		const requestObj = Object.assign({}, ...requestTabArray);
		if (isNonEmptyObject(requestObj)) {
			finalOrderFlowableData = { [defaultFormProp.REQUEST_KEY]: requestObj[defaultFormProp.REQUEST_KEY] };
				if (defaultForm?.rows) {
					defaultForm.rows.forEach(rows => {
						const matchObj = rows?.cols?.[0];
						if (isString(matchObj?.visible)) {
							if (matchObj?.visible.includes(requestObj[defaultFormProp.REQUEST_KEY])) {
								if (requestObj.hasOwnProperty(matchObj?.label)) {
									Object.assign(finalOrderFlowableData, { [matchObj?.label]: requestObj[matchObj?.label] });
								}
							}
						}
					});
				}
		}
		setRequestTabData(finalOrderFlowableData);
		setInitialRequestDate(finalOrderFlowableData);
	}, [taskVariable, defaultForm]);

	const FORM_COMPONENT_IDS = {
		assignTo: "personAssignmentInputView",
		due: "dueDatePickerInputView",
		task: "taskDescriptionTextareaView",
		url: "urlInputView",
		taskStatus: "taskStatusInputView"
	};

	const onAssigneeChange = useCallback((value, options) => {
		setSelectedAssigneeId(value);
		const option = options.find(option => option.value === value);
		setSelectedAssigneeName(option?.text);
		setSelectedAssigneeEmail(option?.person?.contactEmail);
	}, []);

	// Set the focus on the first form field when the modal opens
	useEffect(() => {
		const assignToInputElement = document.getElementById(FORM_COMPONENT_IDS.assignTo) ?
			document.getElementById(FORM_COMPONENT_IDS.assignTo).querySelector("input") :
			null;

		if (visible && assignToInputElement) {
			assignToInputElement.focus();
		}
	}, [FORM_COMPONENT_IDS.assignTo, visible]);

	const getPersonTooltip = () => {
		if(taskVariable?.lastUpdatedBy !== null && Object.keys(taskVariable)?.length > 0) {
			return (
				`${person.firstName} ${person.middleName ? person.middleName + " " : ""}${person.lastName
				}` + "\n" +
				person.contactEmail +
				`${person["affiliations"]?.[0] ? "\n" + person["affiliations"]?.[0].title : ""}` +
				`${personUnit?.name ? "\n" + personUnit.name : ""}`
			);
		} else {
			return null;
		}
	};

	const panes = [
		{
			menuItem: dictionary.tabLabels?.notes || "Notes",
			render: () => (
				<Tab.Pane className={styles.proposalDetailsFormTab}>
					<Form.Group>
						<Form.Field disabled={inputDisabled} required width={MAX_WIDTH}>
							<TextArea
								id={FORM_COMPONENT_IDS.task}
								label={dictionary.task || "Task"}
								onChange={(e, { value }) => { setTaskDescription(value); }}
								rows={10}
								required
								maxLength={4000}
								value={taskDescription}
								disabled={inputDisabled}
								className={inputDisabled ? styles.disabled : ""}
							/>
						</Form.Field>
					</Form.Group>
				</Tab.Pane>
			)
		},
		{
			menuItem: dictionary.tabLabels?.request || "Request",
			render: () => (
				<Tab.Pane className={styles.proposalDetailsFormTab}>
					{Object.keys(requestTabData)?.map((request, index) => {
						return (
							<>
								<Form.Group key={index}>
								<Popup
										style={{ zIndex: 999999 }}
										on="hover"
										disabled={(requestTabData?.[request]).toString().length < 96}
										content={requestTabData?.[request]}
										trigger={
											<Form.Field className={styles.formField} width={MAX_WIDTH}>
												<Form.Input
													id={FORM_COMPONENT_IDS.url}
													readOnly={inputDisabled || isTaskOwner}
													label={request}
													type={"text"}
													name={request}
													value={requestTabData?.[request]}
													onChange={e => changeRequestTypeData(e)}
													disabled={request === (dictionary?.defaultForm?.REQUEST_KEY || "I would like to...") ? requestFormDisabled : !requestFormDisabled}
													className={styles.disableInputField}
												/>
											</Form.Field>
										}
								/>
								</Form.Group>
							</>
						);
					})}
				</Tab.Pane>
			)
		},
	];

	return (
		<>
			<Modal
				className={styles.modal}
				closeIcon
				open={visible}
				onClose={onCancel}
				size="two-thirds"
			>
				<SemanticModal.Header as="h2" className="ui header">
					{dictionary.request || "Request"}
				</SemanticModal.Header>
				<SemanticModal.Content size="large">
					{/* the "novalidate" prop prevents Chrome from being "helpful" and ID'ing required fields
						with its custom tooltips  */}
					<Form noValidate="novalidate" className={styles.taskModalForm}>
						{initialTaskId !== "" &&
							<Form.Group width={MAX_WIDTH}>
								<div className={"ui"}>
									<label style={{ display: "inline" }}>{dictionary?.createdBy ?? "Created by: "}</label>
									<p style={{ display: "inline" }}>{initialOwnerName}</p>
								</div>
							</Form.Group>
						}
						<Form.Group>
							<Form.Field disabled={inputDisabled} required width={MID_WIDTH}>
								<label style={{ display: "block" }} htmlFor={FORM_COMPONENT_IDS.assignTo}>
									{dictionary.assignTo || "Assign To"}
								</label>

								<div id={FORM_COMPONENT_IDS.assignTo}>
									<PersonFinder
										className={styles.personFinder}
										multiple={false}
										disabled={isTaskOwner}
										value={selectedAssigneeId}
										onChange={onAssigneeChange}
										openOnFocus={false}
									/>
								</div>

								<div className={styles.error} style={{ display: isInactiveUserSelected ? "block" : "none" }}>
									{dictionary.inactiveUser || "Selected user is inactive."}
								</div>
							</Form.Field>

							<Form.Field disabled={inputDisabled}>
								<label style={{ display: "block" }} htmlFor={FORM_COMPONENT_IDS.due}>
									{dictionary.due || "Due Date"}
								</label>
								<DateSelector
									id={FORM_COMPONENT_IDS.due}
									className={"ui input"}
									onChange={onDateChange}
									selectedDate={dueDate}
									minDate={todaysDate}
									locale={locale}
									placeholderText={dateFormat}
									disabled={inputDisabled}
									className={inputDisabled ? styles.disabled : ""}
									dateFormat={dateFormat}
								/>
							</Form.Field>
						</Form.Group>
						<Tab className={styles.proposalDetailsTabs} panes={panes} />
						<Form.Group widths="equal">
							<Form.Field disabled={inputDisabled}>
								{(!inputDisabled || !isNonEmptyString(url)) &&
									<Form.Input
										id={FORM_COMPONENT_IDS.url}
										label={dictionary.url || "URL"}
										type={"text"}
										placeholder={inputDisabled ? "" : dictionary.urlPlaceholder || "Add url..."}
										onChange={(e, { value }) => { setUrl(value) }}
										value={url}
										disabled={inputDisabled}
										className={styles.taskModalUrlInput}
									/>}

								{inputDisabled && isNonEmptyString(url) &&
									<>
										<label htmlFor={FORM_COMPONENT_IDS.task}>
											{dictionary.url || "URL"}
										</label>
										<p><a href={url}>{url}</a></p>
									</>
								}
								<div className={styles.error} style={{ display: isUrlValid ? "none" : "block" }}>
									{dictionary.invalidUrl || "Please include http or https in the URL."}
								</div>
							</Form.Field>
						</Form.Group>
						<Form.Group>
							<Form.Field width={MAX_WIDTH}>
								<div className={`field ${styles.taskModalFooterWrapper}`}>
									<div className={styles.taskModalStatusWrapper} style={{ display: "block" }}>
										<div className={styles.taskModalStatusDropdown}>
											<Form.Dropdown
												id={FORM_COMPONENT_IDS.taskStatus}
												width={MIN_WIDTH}
												data-ui-name={FORM_COMPONENT_IDS.taskStatus}
												label={dictionary.requestStatus || "Status"}
												openOnFocus={false}
												selection
												search
												required
												options={taskStatusOptions}
												onChange={onStatusChange}
												value={status}
												disabled={inputDisabled}
												style={{ display: "block" }}
											/>
											<div className={`ui ${styles.taskModalChangedBy}`}>
												<label >Changed by</label>
												<p title={getPersonTooltip()}>
													{taskVariable?.lastUpdatedBy !== null && Object.keys(taskVariable)?.length > 0 ? taskVariable?.lastUpdatedBy : "- -"}
												</p>
											</div>
										</div>
										{initialStatus === "Closed" &&
											<div className={`ui ${styles.taskModalClosedBy}`}>
												<p style={{ display: "inline" }}>by: {initialTaskCompletedBy}</p>
												<p>{initialTaskLastUpdated}</p>
											</div>
										}
									</div>
									<ButtonGroup className={styles.taskModalButtons}>
										<Button tertiary onClick={onCancel}>{dictionary.actionButtonLabels?.cancel || "Cancel"}</Button>
										<span data-tip={disabledReason}> {/* help tool-tip when this button is disabled */}
											<Button onClick={onConfirm}
												disabled={confirmDisabled || isLoading}
												busy={isLoading}
											>
												{dictionary.actionButtonLabels.saveChanges || "Save Changes"}
											</Button>
										</span>
									</ButtonGroup>
								</div>
							</Form.Field>
						</Form.Group>
					</Form>
				</SemanticModal.Content>
			</Modal>
			<ConfirmationModal icon="file alternate outline"
				visible={isConfirmCancelOpen}
				title={dictionary.confirmCancelTitle || "Unsaved Task"}
				message={dictionary.confirmCancel || "There are unsaved changes. Are you sure you want to lose them?"}
				onConfirm={onConfirmCancel}
				onCancel={onAbortCancel}
			/>
		</>
	);
};

RequestModal.propTypes = {
	visible: PropTypes.bool,
	afterConfirm: PropTypes.func,
	afterCancel: PropTypes.func
};

export default RequestModal;


