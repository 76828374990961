import { isNonEmptyString } from "@bit/cayuse.react.utils.type-checks";

const escapeValue = value => value.replace(/(\,|\*)/gi, "\\$1");

const convertSearchTerm = (key, value) => {
    if (isNonEmptyString(value)) {
        return {
            key,
            val: `*${escapeValue(value).trim()}*`
        };
    }

    return {
        key,
        val: value.toString()
    };
};

export const convertToSearchTerms = searchObject => Object.keys(searchObject)
    .filter(key => searchObject[key] !== undefined && searchObject[key] !== null && searchObject[key] !== "")
    .map((key) => convertSearchTerm(key, searchObject[key]));