import {
	isNonEmptyArray,
	isNonEmptyString,
	isNonEmptyObject,
	spUrl,
	encodeSearchTerms,
	isObject
} from "utils/helpers";
import { ADMINISTRATION_USER_URL } from "globals/constants";
import { ADMINISTRATION_PERSON_URL } from "globals/constants";
import { request, patch } from "utils/proxy";

const PATCH_PROPOSAL_BATCH_URL = spUrl("/api/proposal/_bulk");
const SORT_DEFAULT_NAME_ASC = "firstName%2ClastName%2CASC";
const USER_ACCOUNT_ID_EXISTS = "userAccountId%5E";

export const findUsers = ({ pageOffset, pageLimit, searchTerms }) => {
	if (pageOffset === undefined) {
		throw Error("page offset cannot be null");
	} else if (pageLimit === undefined) {
		throw Error("page limit cannot be null");
	} else if (!isNonEmptyArray(searchTerms)) {
		throw Error("searchTerms cannot be null or empty");
	}

	const search = encodeSearchTerms(searchTerms);
	const intPageOffset = parseInt(pageOffset, 10);
	const intPageLimit = parseInt(pageLimit, 10);

	const url = `${ADMINISTRATION_PERSON_URL}?sort=${SORT_DEFAULT_NAME_ASC}&page=${intPageOffset}&size=${intPageLimit}&search=${USER_ACCOUNT_ID_EXISTS}%2C${search}`;

	return request(url)
		.then(data => {
			const persons = data?._embedded?.persons;
			const hasPersons = isNonEmptyArray(persons);

			return hasPersons ? persons : [];
		})
		.catch(error => {
			let msg = isNonEmptyString(error)
				? error
				: error.message || "Something went wrong";

			console.error(msg, error);
		});
};

/**
 * This method is called from either the Dashboard page or the Proposal Detail page, so it's designed
 * to expect only ProposalFeedItem objects. Proposal objects are unavailable on the Dashboard.
 * It's up to the caller to update its view based on the results of this operation itself.
 * @param {*} proposalFeedItems
 * @param {*} user
 * @param {*} role
 * @returns An array of objects mapping proposal Jest ids to arrays of admin team members.
 */
export const addAdminTeamMemberWithRole = (proposalFeed, user, role) => {
	const proposalFeedItems = Array.isArray(proposalFeed) ? proposalFeed : [ proposalFeed ];
	const hasProposalFeedItems = isNonEmptyArray(proposalFeedItems);
	const hasUser = isNonEmptyObject(user);
	const hasRole = isNonEmptyObject(role);

	if (!hasProposalFeedItems || !hasUser || !hasRole) {
		console.warn("ProposalFeedItems, user, and role are all required.");

		return;
	}

	const adminTeamMemberCandidate = {
		personId: user.personId,
		roleId: role.id,
		fullName: user.fullName,
		roleName: role.name
	};

	const bulkPartialUpdateProposals = proposalFeedItems.map(proposalFeedItem => {
		const proposal = {
			id: proposalFeedItem.id,
			proposalId: proposalFeedItem.proposalId,
			adminTeamMembers: []
		};
		if (!Array.isArray(proposalFeedItem.adminTeamMemberData)) {
			proposal.adminTeamMembers.push(adminTeamMemberCandidate);

			return proposal;
		}

		if (proposalFeedItem.adminTeamMemberData.length === 0) {
			proposal.adminTeamMembers.push(adminTeamMemberCandidate);

			return proposal;
		}

		const isUserToAddNotPresent = proposalFeedItem.adminTeamMemberData
			.every(adminTeamMember => adminTeamMemberCandidate.personId !== adminTeamMember.personId);

		if (isUserToAddNotPresent) {
			proposalFeedItem.adminTeamMemberData.push(adminTeamMemberCandidate);
			proposal.adminTeamMembers = proposalFeedItem.adminTeamMemberData;

			return proposal;
		}

		proposal.adminTeamMembers = proposalFeedItem.adminTeamMemberData;

		return proposal;
	});

	return patch(PATCH_PROPOSAL_BATCH_URL, bulkPartialUpdateProposals)
		.then(results => handleBulkPatchResponse(results, bulkPartialUpdateProposals));
};

export const removeAdminTeamMember = (adminTeamMember, proposalFeedItem, adminTeamMembers) => {
	const isAdminTeamMemberPresent = isObject(adminTeamMember);
	const isProposalFeedItemPresent = isObject(proposalFeedItem)
	const isProposalJestIdPresent = isNonEmptyString(proposalFeedItem.id);
	const isProposalIdPresent = isNonEmptyString(proposalFeedItem.proposalId);
	const isAdminTeamMemberListPresent = isNonEmptyArray(adminTeamMembers);

	if (!isAdminTeamMemberPresent
		|| !isProposalFeedItemPresent
		|| !isProposalJestIdPresent
		|| !isProposalIdPresent
		|| !isAdminTeamMemberListPresent) {
		console.error("AdminTeamMember, proposal with Jest id and proposal id, and adminTeamMemberList must all be present.");

		return;
	}

	const adminTeamMembersMinusOne = adminTeamMembers.filter(teamMember => adminTeamMember.personId !== teamMember.personId);

	const proposalPatchData = [ {
		id: proposalFeedItem.id,
		proposalId: proposalFeedItem.proposalId,
		adminTeamMembers: adminTeamMembersMinusOne
	} ];

	return patch(PATCH_PROPOSAL_BATCH_URL, proposalPatchData)
		.then(results => handleBulkPatchResponse(results, proposalPatchData));
};

const handleBulkPatchResponse = (results, proposalPatchData) => {
	const isUpdateSuccessful = results.every(bulkReportItem => bulkReportItem.success);
	if (!isUpdateSuccessful) {
		return Promise.reject(results);
	}

	return Promise.resolve(proposalPatchData);
};
