import store from "utils/store";
import { form as constants } from "store/constants";
import { actionFactory as fetchableActionFactory } from "store/factories/fetchable";
import { isNonEmptyString, spUrl, proxy, groupFieldSections, generateId } from "utils";
import { API_FORMS, NEW_RULE_ID } from "globals/constants";

const { setIsFetching, setError, setContent } = fetchableActionFactory(constants);

const dispatch = store.dispatch;

export const setRules = rules => dispatch({ type: constants.SET_RULES, rules });

export const getForm = formModelId => {
	const url = spUrl(`${API_FORMS}/${formModelId}`);

	setIsFetching(true);
	setError("");

	return proxy.request(url)
		.then(form => {
			form.formElements = form.elements;
			delete form.elements;
			const formSections = groupFieldSections(form.formElements);

			form.rules = form.rules.map((rule, index) => ({
				...rule,
				id: index
			}));

			setContent({
				...form,
				formSections
			});
		})
		.catch(error => {
			let msg = isNonEmptyString(error)
				? error
				: error.message || "Something went wrong";

			setError(msg);
		})
		.finally(() => {
			setIsFetching(false);
		});
};

export const getRules = formId => {
	const url = `${API_FORMS}/${formId}/rules`;

	// todo: fetch, update redux store
};

export const updateRules = (formId, rules) => {
	const parsedFormId = formId.replace("FORM_MODEL-", "");

	const url = spUrl(`${API_FORMS}/${parsedFormId}/rules`);

	return proxy.put(url, rules)
		.then(result => {
			const updatedRules = rules.map(rule => {
				return rule.id === NEW_RULE_ID
					? { ...rule, id: generateId("rule") }
					: { ...rule };
			});

			setRules(updatedRules);
		})
		.catch(error => {
			// todo: sad toast message
		});
};