import store from "utils/store";
import { DEFAULT_PAGE_LIMIT } from "globals/constants";
import {
	isNonEmptyArray,
	isNonEmptyString,
	isNumber,
	logMissingFactoryConstant
} from "utils";


export const actionFactory = (constants, actions) => {
	const requestedActions = isNonEmptyArray(actions)
		? actions
		: [ "addItems", "setItems", "setPaging", "setSort" ];

	const dispatch = store.dispatch;

	const createAddItems = () => isNonEmptyString(constants.ADD_ITEMS)
		? items => dispatch({ type: constants.ADD_ITEMS, items })
		: logMissingFactoryConstant("ADD_ITEMS");

	const createSetItems = () => isNonEmptyString(constants.SET_ITEMS)
		? items => dispatch({ type: constants.SET_ITEMS, items })
		: logMissingFactoryConstant("SET_ITEMS");

	const createSetPaging = () => isNonEmptyString(constants.SET_PAGING)
		? ({ pageNumber, pageLimit }) => dispatch({ type: constants.SET_PAGING, pageNumber, pageLimit })
		: logMissingFactoryConstant("SET_PAGING");

	const createSetSort = () => isNonEmptyString(constants.SET_SORT)
		? (column, sortDirection = "ascending") => {
			const direction = sortDirection === "ascending" ? "ascending" : "descending";

			dispatch({ type: constants.SET_SORT, sort: { column, direction } });
		}
		: logMissingFactoryConstant("SET_SORT");

	const actionHash = {
		addItems: createAddItems,
		setItems: createSetItems,
		setPaging: createSetPaging,
		setSort: createSetSort
	};

	const output = {};

	requestedActions.forEach(action => {
		if (action in actionHash) {
			output[action] = actionHash[action]();
		}
	});

	return output;
};

export const initialState = {
	content: {
		items: [],
		searchItems: [],
		totalItems: 0,
		sort: {
			column: "dueDate",
			direction: "ascending"
		},
		paging: {
			pageNumber: 1,
			pageLimit: DEFAULT_PAGE_LIMIT
		},
		search: {},
		summary: []
	}
};

export const reduceSetItems = (state, { items }) => ({
	...state,
	content: {
		...state.content,
		items: [ ...items ]
	}
});

const invertSortDirection = (direction) => direction === "ascending" ? "descending" : "ascending";

export const reduceSetSort = (state, { sort: { column, direction } }) => {
	const sortDirection = state?.content?.sort?.column === column ? invertSortDirection(state?.content?.sort?.direction) : direction;
	return {
		...state,
		content: {
			...state.content,
			sort: {
				column,
				direction: sortDirection
			}
		}
	};
};

export const reduceSetSearch = (state, { search }) => ({
	...state,
	content: {
		...state.content,
		search: {
			...search
		}
	}
});

export const reduceSetSummary = (state, { summary }) => ({
	...state,
	content: {
		...state.content,
		summary: [
			...summary
		]
	}
});

export const reduceSetPaging = (state, { pageNumber, pageLimit }) => ({
	...state,
	content: {
		...state.content,
		paging: {
			...state.content.paging,
			...(isNumber(pageNumber) ? { pageNumber } : {}),
			...(isNumber(pageLimit) ? { pageLimit } : {})
		}
	}
});

export const addItems = (state, { items }) => ({
	...state,
	content: {
		...state.content,
		items: [
			...state.content.items,
			...items
		]
	}
});

const reducers = {
	reduceSetItems,
	reduceSetSort,
	reduceSetPaging,
	addItems
};

export default { actionFactory, initialState, reducers };