export const form = {
	IS_FETCHING: "FORM_IS_FETCHING",
	SET_ERROR: "FORM_SET_ERROR",
	SET_CONTENT: "FORM_SET_CONTENT",
	SET_RULES: "FORM_SET_RULES",
	RESET: "FORM_RESET"
};

export const groupRoles = {
	IS_FETCHING: "GROUP_ROLES_IS_FETCHING",
	SET_ERROR: "GROUP_ROLES_SET_ERROR",
	SET_CONTENT: "GROUP_ROLES_SET_CONTENT",
	SET_ITEMS: "GROUP_ROLES_SET_ITEMS",
	RESET: "GROUP_ROLES_RESET"
};

export const picklists = {
	IS_FETCHING: "PICKLISTS_IS_FETCHING",
	SET_ERROR: "PICKLISTS_SET_ERROR",
	SET_CONTENT: "PICKLISTS_SET_CONTENT",
	SET_OPTIONS: "PICKLISTS_SET_OPTIONS"
};

export const project = {
	IS_FETCHING: "SINGLE_PROJECT_IS_FETCHING",
	SET_ERROR: "SINGLE_PROJECT_SET_ERROR",
	SET_CONTENT: "SINGLE_PROJECT_SET_CONTENT",
	RESET: "SINGLE_PROJECT_RESET"
};

export const projects = {
	IS_FETCHING: "PROJECTS_IS_FETCHING",
	SET_ERROR: "PROJECTS_SET_ERROR",
	SET_CONTENT: "PROJECTS_SET_CONTENT",
	SET_ITEMS: "PROJECTS_SET_ITEMS",
	ADD_ITEMS: "PROJECTS_ADD_ITEMS",
	SET_SORT: "PROJECTS_SET_SORT",
	SET_PAGING: "PROJECTS_SET_PAGING",
	SET_ACTIVE_COLS: "PROJECTS_SET_ACTIVE_COLS",
	RESET: "PROJECTS_RESET",
	SET_SUMMARY: "PROJECTS_SET_SUMMARY",
	SET_SEARCH: "PROJECTS_SET_SEARCH"
};

export const proposal = {
	IS_FETCHING: "SINGLE_PROPOSAL_IS_FETCHING",
	SET_ERROR: "SINGLE_PROPOSAL_SET_ERROR",
	SET_CONTENT: "SINGLE_PROPOSAL_SET_CONTENT",
	SET_VALUES: "SINGLE_PROPOSAL_SET_VALUES",
	RESET: "SINGLE_PROPOSAL_RESET"
};

export const proposals = {
	IS_FETCHING: "PROPOSALS_IS_FETCHING",
	SET_ERROR: "PROPOSALS_SET_ERROR",
	SET_CONTENT: "PROPOSALS_SET_CONTENT",
	SET_ITEMS: "PROPOSALS_SET_ITEMS",
	ADD_ITEMS: "PROPOSALS_ADD_ITEMS",
	SET_SORT: "PROPOSALS_SET_SORT",
	SET_PAGING: "PROPOSALS_SET_PAGING",
	SET_AVAILABLE_COLS: "PROPOSALS_SET_AVAILABLE_COLS",
	SET_ACTIVE_COLS: "PROPOSALS_SET_ACTIVE_COLS",
	RESET: "PROPOSALS_RESET",
	SET_SUMMARY: "PROPOSALS_SET_SUMMARY",
	SET_SEARCH: "PROPOSALS_SET_SEARCH"
};

export const shell = {
	IS_FETCHING: "SHELL_IS_FETCHING",
	SET_ERROR: "SHELL_SET_ERROR",
	SET_CONTENT: "SHELL_SET_CONTENT",
	SET_CAYUSE_HEADER: "SHELL_SET_CAYUSE_HEADER",
	SET_LOCALE: "SHELL_SET_LOCALE",
	RESET: "SHELL_RESET"
};

export const task = {
	IS_FETCHING: "SINGLE_TASK_IS_FETCHING",
	SET_ERROR: "SINGLE_TASK_SET_ERROR",
	SET_CONTENT: "SINGLE_TASK_SET_CONTENT",
	RESET: "SINGLE_TASK_RESET",
};

export const defaultForm = {
	IS_FETCHING: "DEFAULT_FORM_IS_FETCHING",
	SET_ERROR: "DEFAULT_FORM_SET_ERROR",
	SET_CONTENT: "DEFAULT_FORM_SET_CONTENT",
	ADD_DEFAULT_FORM: "ADD_DEFAULT_FORM"
};

export const tasks = {
    IS_FETCHING: "TASKS_IS_FETCHING",
    SET_ERROR: "TASKS_SET_ERROR",
	SET_CONTENT: "TASKS_SET_CONTENT",
	ADD_ITEMS: "TASKS_ADD_ITEMS",
    RESET: "TASKS_RESET",
	SET_FILTERS: "TASKS_SET_FILTERS",
    SET_ITEMS: "TASKS_SET_ITEMS",
    SET_SORT: "TASKS_SET_SORT",
    SET_PAGING: "TASKS_SET_PAGING",
    SET_SEARCH: "TASKS_SET_SEARCH"
};

export const tenant = {
	SET_ERROR: "TENANT_SET_ERROR",
	SET_CONTENT: "TENANT_SET_CONTENT"
};

export const notification = {
	NOTIFY: "NOTIFY"
};

export default {
	groupRoles,
	projects,
	proposals,
	task,
    tasks,
	tenant,
	shell,
	notification
};
