import React from "react";
import PropTypes from "prop-types";
import { isNonEmptyString } from "utils";

import styles from "./styles.css";


const CovertButton = ({ children, className, ...rest }) => (
	<button className={`${styles.covertButton} ${isNonEmptyString(className) ? className : ""}`} {...rest}>
		{children}
	</button>
);

CovertButton.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string
};

export default CovertButton;
