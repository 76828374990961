import React from "react";
import PropTypes from "prop-types";
import { isNonEmptyString } from "utils";

import styles from "./styles.css";


const SubHeader = ({ children, className, headerLevel }) => {
	// if we are passed a valid aria-level value (1-6), use it, otherwise default to a second level header
	// (the common header contains the top level header, so level 2 guarantees an accessible header descendancy order)
	headerLevel = headerLevel && parseInt(headerLevel) >= 1 && parseInt(headerLevel) <= 6 ? headerLevel : "2";

	return (
		<div role="heading" aria-level={headerLevel} className={`${styles.subHeader} ${isNonEmptyString(className) ? className : ""}`}>
			{children}
		</div>
	);
};

SubHeader.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default SubHeader;
