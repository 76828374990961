import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import reducers from "store/reducers";
import { isObject } from "utils";
import thunk from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	combineReducers({ ...reducers }),
	composeEnhancers(applyMiddleware(thunk))
);

export default store;

export const getStore = () => {
	return isObject(store)
		? Promise.resolve(store)
		: new Promise(resolve => {
			setInterval(() => {
				if (isObject(store)) {
					resolve(store);
				}
			}, 20);
		});
};