import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Icon, Modal as SemanticModal } from "semantic-ui-react";
import { useDictionary } from "hooks";
import { isFunction } from "utils";
import VerticalSpacing from "components/VerticalSpacing";
import Button from "components/Button";
import Modal from "components/Modal";

import styles from "./styles.css";


const ConfirmationModal = ({ cancelButtonLabel, confirmButtonLabel, icon, message, onCancel, onConfirm, title, visible }) => {
	const dictionary = useDictionary("common.confirmationModal");

	const handleConfirm = () => {
		if (isFunction(onConfirm)) {
			onConfirm();
		}
	};

	const handleCancel = () => {
		if (isFunction(onCancel)) {
			onCancel();
		}
	};

	// When the modal is opened, focus one of the action buttons
	useEffect( () => {
		if (!visible) { return }
		const cancelButton = document.querySelector(".confirmation-modal-cancelbutton");
		const confirmButton = document.querySelector(".confirmation-modal-cancelbutton");

		if (confirmButton) {
			confirmButton.focus();
		} else {
			cancelButton.focus();
		}
	}, [ visible ]);

	return (
		<Modal
			className={styles.transparentModal}
			open={visible}
			onClose={handleConfirm}
		>
			<SemanticModal.Header className={`ui header ${styles.transparentModalHeader}`}>
				{title}
			</SemanticModal.Header>
			<SemanticModal.Content className={styles.transparentModalContent}>
				{icon && <div>
					<Icon
						aria-hidden="true"
						className={styles.transparentModalContentIcon}
						name={icon}
					/>
				</div>}
				<div>
					{message}
				</div>
			</SemanticModal.Content>
			<VerticalSpacing/>
			<SemanticModal.Actions className={styles.transparentModalActions}>
				{isFunction(onCancel) && <Button className={styles.transparentModalActionButtonSecondary + "  confirmation-modal-cancelbutton"} onClick={handleCancel}>
					{cancelButtonLabel || dictionary.cancelButtonLabel || "Cancel"}
				</Button>}
				{isFunction(onConfirm) && <Button className={styles.transparentModalActionButtonPrimary + "  confirmation-modal-confirmbutton"} onClick={handleConfirm}>
					{confirmButtonLabel || dictionary.confirmButtonLabel || "Confirm"}
				</Button>}
			</SemanticModal.Actions>
		</Modal>
	);
};

ConfirmationModal.propTypes = {
	icon: PropTypes.string,
	message: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.string
	]),
	onCancel: PropTypes.func,
	onConfirm: PropTypes.func,
	title: PropTypes.string,
	visible: PropTypes.bool
};

export default ConfirmationModal;
