import { groupRoles as constants } from "store/constants";
import { actionFactory as feedActionFactory } from "store/factories/feed";
import { actionFactory as fetchableActionFactory } from "store/factories/fetchable";
import { isNonEmptyString, spUrl } from "utils";
import { API_GROUP_ROLES } from "globals/constants";
import { request } from "utils/proxy";

const { setItems } = feedActionFactory(constants, [ "setItems" ]);

const { setIsFetching, setError, setContent } = fetchableActionFactory(constants);

const findGroupRoles = () => {
	const url = spUrl(API_GROUP_ROLES);

	setIsFetching(true);
	setError("");

	return request(url)
		.then(roles => {
			setContent({ roles });
		})
		.catch(error => {
			let msg = isNonEmptyString(error)
				? error
				: error.message || "Something went wrong";

			setError(msg);
		})
		.finally(() => {
			setIsFetching(false);
		});
};

export {
	setItems,
	setIsFetching,
	setError,
	setContent,
	findGroupRoles
};