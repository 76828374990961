import { dictionary } from "./dictionary";
import { form } from "./form";
import { groupRoles } from "./groupRoles";
import { task } from "./task";
import { tasks } from "./tasks";
import { shell } from "./shell";
import { notifications } from "./notifications";
import { defaultForm } from "./defaultForm";

export default {
	dictionary,
	form,
	groupRoles,
	task,
    tasks,
	shell,
	defaultForm,
	notifications
};
