import React, { useEffect, useMemo, useRef } from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import { Modal as SemanticModal } from "semantic-ui-react";
import { isNonEmptyString, crunchClasses, getKeyboardFocusableElements } from "utils";

import styles from "./styles.css";

const Modal = ({ children, open, role, className, size, closeIcon, ...rest }) => {
	const modalRef = useRef();
	const modalClassName = useMemo(() => {
		return crunchClasses({
			[styles.modal]: true,
			[styles.oneThird]: size === "one-third",
			[styles.oneHalf]: size === "one-half",
			[styles.twoThirds]: size === "two-thirds",
			[className]: isNonEmptyString(className)
		});
	}, [ className, size ]);

	useEffect(() => {
			ReactTooltip.rebuild();
	}, [ open ]);

	const handleKeyDown = evt => {
		// get all the focusable elements in the modal, in order of DOM appearance
		const focusableElements = getKeyboardFocusableElements(modalRef.current.ref.current);

		// if we are not tabbing, if the modal is not open, or if we have no tabbable elements, return
		if (evt.key !== "Tab" || !open || focusableElements.length === 0) {
			return;
		}

		// if the shift key is applied, we are reverse tabbing, and the first and last
		// focusable elements are reversed
		const terminalElementIndex = evt.shiftKey ? 0 : focusableElements.length - 1;
		const wrapAroundElementIndex = evt.shiftKey ? focusableElements.length - 1 : 0;

		// if we are currently focused on a terminal element, wrap the focus around to the other 
		// terminal focusable element
		if (document.activeElement === focusableElements[terminalElementIndex]) {
			evt.preventDefault();
			focusableElements[wrapAroundElementIndex].focus();
		}
	};

	return (
		<SemanticModal
			// the default Semantic UI close button for modals is not accessible, but the framework allows us to pass in a custom component for the close button.
			// So if a close button is requested, pass in an accessible component to use instead
			closeIcon={ closeIcon ? <button aria-label="Close" className="ui icon button modal-button-close"><i aria-hidden="true" className="close icon"></i></button> : false }
			// we only allow two roles for a modal. If we haven't been passed the more specific "alertdialog" role, default to the more general "dialog"
			role={role !== "alertdialog" ? "dialog" : "alertdialog"}
			aria-modal="true"
			className={modalClassName}
			ref={modalRef}
			open={open}
			onKeyDown={handleKeyDown}
			{...rest}
		>
			{children}
		</SemanticModal>
	);
};

Object.assign(Modal, {
		Actions: SemanticModal.Actions,
		Content: SemanticModal.Content,
		Description: SemanticModal.Description,
		Header: SemanticModal.Header
});

Modal.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	open: PropTypes.bool,
	size: PropTypes.oneOf([ "one-third", "one-half", "two-thirds" ]),
	role: PropTypes.oneOf([ "dialog", "alertdialog" ]),
	closeButton: PropTypes.oneOfType([ PropTypes.node, PropTypes.object, PropTypes.bool ])
};

export default Modal;