import React, { useMemo, useRef, useEffect } from "react";
import { generateId } from "utils";
import {default as DateSelectors} from "@bit/cayuse.react.components.date-selector";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import styles from "./styles.css";


const DateSelector = ({
  customInputClass,
  customWrapperClass,
  onChange,
  selectedDate,
  ...rest
}) => {
  const BASE_DATEPICKER_ID = useMemo( () => generateId("datePicker"), [] );
  const datePickerRef = useRef();

  // The "Tab" key is used for navigating focusable elements on the page. When a DatePicker receives a tab
  // it should close the menu and focus should move to the next focusable item on the page, not inside the DatePicker.
  // TODO: This is a known bug with react-datepicker: https://github.com/Hacker0x01/react-datepicker/issues/2058 and is currently
  // unresolved as of this work. When the bug has been addressed, we should remove this handler.
  const handleKeydown = evt => {
    if (evt.key === "Tab") {
      datePickerRef.current.setOpen(false);
    }
  };

  // The aria-describedby attribute needs to be set on the input element since it's the "control" element for the 
  // datepicker.  This is a very non-react approach, but I don't know a better way to do it since we don't have access
  // to the source code.
  useEffect( () => {
    datePickerRef.current 
      && datePickerRef.current.input 
      && datePickerRef.current.input.setAttribute("aria-describedby", `${BASE_DATEPICKER_ID}-description`);
  }, [ BASE_DATEPICKER_ID ]);

  return (
    <div 
      className={`${styles.dateSelector} ${customWrapperClass || ""}`}
    >
      <DateSelectors
        className={`${styles.dateSelectorInput} ${customInputClass || ""}`}
        onChange={(date) => onChange(date)}
        selected={selectedDate}
        ref={datePickerRef}
        onKeyDown={handleKeydown}
        {...rest}
      >
        <div id={`${BASE_DATEPICKER_ID}-description`} className="accessibility-text">
          <h3>Keyboard navigation:</h3>
          <ul>
            <li>Use the down arrow to move to the calendar to start selecting dates.</li>
            <li>Once you are in the calendar, use left and right arrows to navigate to the previous or next day, and up and down arrows
              to navigate to the previous or next week.</li>
            <li>Use page up or page down to navigate to navigate one month forward or backward.</li>
            <li>Use home or end to navigate one year forward or backward.</li>
            <li>You will not be able to navigate beyond or select a day earlier than the current day.</li>
            <li>Use enter to select a date.</li>
            <li>Use shift+tab to return to the input field.  Use tab to leave the datepicker and navigate to the next page item.</li>
            <li>Use escape to close the modal and return to the main page.</li>
          </ul>
        </div>
      </DateSelectors>
    </div>
  );
};

export default DateSelector;
