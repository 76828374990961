import deepMerge from "deepmerge";
import englishDictionary from "globals/dictionaries/en";

const frenchDictionary = {
	dashboard: {
		heading: "SP Tableau de Bord"
	},
	proposals: {
		startNew: "Lancer une nouvelle proposition"
	},
	projects: {
		tableHeadings: { // todo: do these need to be here or will translated field labels be available elsewhere?
			projectTitle: "Titre du projet",
			projectNumber: "Numéro",
			status: "Statut"
		}
	},
	pagingControls: {
		showing: "Affichage de",
		xOfYSeparator: "sur",
		items: "articles"
	}
};

export default deepMerge(englishDictionary, frenchDictionary);