import { request } from "utils/proxy";
import store from "utils/store";
import { shell as constants } from "store/constants";
import { actionFactory as fetchableActionFactory } from "store/factories/fetchable";
import {
	isNonEmptyString,
	spUrl,
	isNonEmptyArray,
	prepareColumnHeaders,
	slugifyString,
	sortByAlpha,
	getUserDetailsFromCayuseHeader,
} from "utils";

const dispatch = store.dispatch;

const { setIsFetching, setError, setContent } = fetchableActionFactory(constants);

const setCayuseHeader = cayuseHeader => dispatch({ type: constants.SET_CAYUSE_HEADER, cayuseHeader });

const setLocale = locale => dispatch({type: constants.SET_LOCALE, locale});

const fetchShell = () => {
	setIsFetching(true);
	setError("");

	// TODO: Move this to common location so we don't have to make the same API request as common header
	const fetchUserDetails = ({ username, jti }) => {
		return request(`/api/v2/tenant/product/user/${username}?${jti}`);
	};

	return getUserDetailsFromCayuseHeader()
		.then(fetchUserDetails)
		.then(content => {
			setContent(content);
		})
		.catch(error => {
			let msg = isNonEmptyString(error)
				? error
				: error.message || "Something went wrong";

			setError(msg);
		})
		.finally(() => {
			setIsFetching(false);
		});
};

export {
	setIsFetching,
	setError,
	setContent,
	setCayuseHeader,
	setLocale,
	fetchShell
};
