import React from "react";
import PropTypes from "prop-types";
import { Icon as SemanticIcon } from "semantic-ui-react";
import { crunchClasses } from "utils";

import styles from "./styles.css";


const Icon = ({ size, ...rest }) => {
	const classes = crunchClasses({
		[styles.icon]: true,
		[styles.smaller]: size === "smaller",
		[styles.medium]: size === "medium",
		[styles.bigger]: size === "bigger"
	});

	return <SemanticIcon data-component="Icon" className={classes} {...rest} />;
};

Icon.propTypes = {
	size: PropTypes.oneOf([ "bigger", "medium", "smaller" ])
};

export default Icon;