import React from "react";

class Pager extends React.Component {
    static pageBegin(data, limit, offset) {
        const pageLimit = Pager.pageLimit(limit);
        const pageOffset = Pager.pageOffset(offset);
        const pageBegin = pageOffset * pageLimit;

        return pageBegin;
    }

    static pageCount(data, limit) {
        const pageLimit = Pager.pageLimit(limit);
        const totalPages = data && data.length > 0
            ? Math.floor( (data.length - 1) / pageLimit) + 1
            : 0
        ;

        return totalPages;
    }

    static pageEnd(data, limit, offset) {
        const pageLimit = Pager.pageLimit(limit);
        const pageBegin = Pager.pageBegin(data, limit, offset);
        const itemCount = Pager.pageCount(data);
        const pageEnd = itemCount > pageBegin + pageLimit ?  pageBegin + pageLimit : itemCount;

        return pageEnd;
    }

    static pageLimit(limit) {
        return limit && limit > 0 ? limit: 1;
    }

    static pageSlice(data, limit, offset) {
        const pageBegin = Pager.pageBegin(data, limit, offset);
        const pageEnd = Pager.pageEnd(data, limit, offset);
        const pageResults = data.slice(pageBegin, pageEnd);

        return pageResults;
    }

    static pageOffset(offset) {
        return offset && offset > 0 ? offset : 0;
    }

    static pageOffsetByPageNumber(pageNumber) {
        return pageNumber && pageNumber > 0 ? pageNumber - 1 : 0;
    }
}

export default Pager;