import { useSelector } from "react-redux";
import { isNonEmptyString } from "utils";

/**
 * key is a string "path" to the desired dictionary. Separate "levels" with a period.
 * Example: "common.assignSelected"
 */
export const useDictionary = key => {
	const dictionaryStore = useSelector(state => state.dictionary);

	if (isNonEmptyString(key)) {
		const path = key.split(".");

		return path.reduce((dictionary, currentKey) => {
			return currentKey in dictionary ? dictionary[currentKey] : {};
		}, dictionaryStore);
	}

	return dictionaryStore;
};

export default useDictionary;