import { picklists as constants } from "store/constants";
import { findTeams, isNonEmptyString } from "utils";
import { request } from "utils/proxy";
import store from "utils/store";


const dispatch = store.dispatch;

const setPicklistIsFetching = (picklistId, isFetching) => dispatch({
	type: constants.IS_FETCHING,
	picklistId,
	isFetching
});

const setPicklistHasError = (picklistId, hasError) => dispatch({
	type: constants.SET_ERROR,
	picklistId,
	hasError
});

const setPicklistOptions = (picklistId, options) => dispatch({
	type: constants.SET_OPTIONS,
	picklistId,
	options
});

const fetchPicklistOptions = (picklistId, url) => {
	let promise;

	if (picklistId === "adhocteams") {
		promise = findTeams();
	} else if (isNonEmptyString(url)) {
		promise = request(url); // todo: flesh this out once we know how to get options from Flowable
	} else {
		const msg = `Unable to identify endpoint to fetch options for ${picklistId}`;

		setPicklistHasError(picklistId, msg);

		throw new Error(msg);
	}

	setPicklistIsFetching(picklistId, true);
	setPicklistHasError(picklistId, "");

	promise
		.then(options => {
			console.log("resulting options %o", options);

			setPicklistOptions(picklistId, options);
		})
		.catch(error => {
			let msg = isNonEmptyString(error)
				? error
				: error.message || "Something went wrong";

			setPicklistHasError(picklistId, msg);
		})
		.finally(() => {
			setPicklistIsFetching(picklistId, false);
		});
};

export {
	setPicklistIsFetching,
	setPicklistHasError,
	setPicklistOptions,
	fetchPicklistOptions
};
