import { groupRoles as gr } from "store/constants";
import {
	reduceIsFetching,
	reduceSetError,
	reduceShallowContent,
	initialState as initialFetchableState
} from "store/factories/fetchable";

const initialState = {
	...initialFetchableState,
	content: {
		roles: []
	}
};

export function groupRoles(state = initialState, action) {
	switch (action.type) {
		case gr.IS_FETCHING:
			return reduceIsFetching(state, action);
		case gr.SET_ERROR:
			return reduceSetError(state, action);
		case gr.SET_CONTENT:
			return reduceShallowContent(state, action);
		case gr.SET_ITEMS:
			return {
				...state,
				content: {
					...state.content,
					roles: [ ...action.roles ]
				}
			};
		case gr.RESET:
			return initialState;
		default:
			return state;
	}
}


