import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { generateId, isNonEmptyString, crunchClasses } from "utils";

import styles from "./styles.css";


const Button = ({
	children,
	className,
	primary,
	secondary,
	tertiary,
	disabled,
	small,
	ariaDescription,
	tooltip,
	...rest
}) => {
	const crunchedClasses = useMemo(() => crunchClasses({
		[styles.button]: true,
		[styles.primary]: primary || (!secondary && !tertiary) ? styles.primary : "",
		[styles.secondary]: secondary,
		[styles.tertiary]: tertiary,
		[styles.disabled]: disabled,
		[styles.small]: small,
		...(isNonEmptyString(className) ? { [className]: true } : {})
	}), [ className, primary, secondary, tertiary, disabled, small ]);

	const hasAriaDescription = isNonEmptyString(ariaDescription);
	const ariaId = useMemo(() => generateId("button-aria-description"), []);

	useEffect(() => {
		ReactTooltip.rebuild();

		return () => {
			ReactTooltip.hide();
		};
	}, [ tooltip ]);

	return (
		<button data-component="Button"
				className={crunchedClasses}
				disabled={disabled}
				{...(hasAriaDescription ? { "aria-describedby": ariaId } : {})}
				{...rest}
		>
			{hasAriaDescription &&
				<span className={styles.ariaDescription} id={ariaId}>{ariaDescription}</span>
			}

			{isNonEmptyString(tooltip) &&
				<span data-tip={tooltip} data-for="react-tooltip" className={styles.tip} />
			}

			{children}
		</button>
	);
};

Button.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	primary: PropTypes.bool,
	secondary: PropTypes.bool,
	tertiary: PropTypes.bool,
	small: PropTypes.bool,
	disabled: PropTypes.bool,
	ariaDescription: PropTypes.string,
	tooltip: PropTypes.string
};

export default Button;