import store from "utils/store";
import { defaultForm as constants } from "store/constants";
import { actionFactory as fetchableActionFactory } from "store/factories/fetchable";
import { put, request, save, executeRequest } from "utils/proxy";

import { isNonEmptyString, convertToSearchTerms, encodeSearchTerms } from "utils";
import { FLOWABLE_BASE_URL } from "globals/constants";


const { setIsFetching, setError, setContent } = fetchableActionFactory(constants);

const dispatch = store.dispatch;

const getDefaultForm = () => {
    setError("");
    setIsFetching(true, { isRefresh: true });

    const URL = `${FLOWABLE_BASE_URL}/process-api/runtime/tasks`;
    const params = {
        formKey: "defaultRequestForm",
        category: "CAYUSE_MY_REQUEST",
    };

    return save(URL, params).then(async response => {
        const defaultForm = await request(`${FLOWABLE_BASE_URL}/process-api/runtime/tasks/${response?.id}/form`);

        dispatch({ type: constants.SET_CONTENT, content: { taskDefaultForm: defaultForm?.exception ? [] : defaultForm, taskDetail: response } });

        return defaultForm;
    }).catch(error => {
        let msg = isNonEmptyString(error)
            ? error
            : error.message || "Something went wrong";

        setError(msg);
    })
        .finally(() => {
            setIsFetching(false, { isRefresh: false });
        });
};

const updateTaskData = (flowableFormData) => {
    const { defaultForm, dictionary, shell } = store.getState();

    const taskDetail = defaultForm?.content?.taskDetail;
    const taskPayload = dictionary?.tasks;
    const shellContent = shell?.content;

    try {
        const data = {
            category: taskPayload?.taskUpdatePayload?.category || "CAYUSE_MY_REQUEST",
            status: taskPayload?.taskUpdatePayload?.status || "Open",
            name: flowableFormData?.[taskPayload?.defaultForm?.REQUEST_KEY || "I would like to..."],
            assigneeId: taskDetail?.assignee,
            taskId: taskDetail?.id,
            assigneeName: shellContent?.person?.fullName,
            assignedFromId: shellContent?.user?.id,
            assignedFromName: shellContent?.person?.fullName || taskPayload?.taskUpdatePayload?.unLinkUser,
        };
        const updateTaskUrl = `${FLOWABLE_BASE_URL}/cayuse-tasks/${taskDetail?.id}`;
        
        return put(updateTaskUrl, data);
    } catch (error) {
        throw new Error();
    }
};

const saveFlowableFormData = (flowableFormData) => {
    const defaultFormData = store.getState().defaultForm?.content;
    const taskDictionary = store.getState().dictionary?.tasks;

    setError("");
    setIsFetching(true, { isRefresh: true });

    const saveFlowableFormURL = `${FLOWABLE_BASE_URL}/platform-api/tasks/${defaultFormData?.taskDetail?.id}/save-form`;
    const getSavedFlowableForm = `${FLOWABLE_BASE_URL}/platform-api/tasks/${defaultFormData?.taskDetail?.id}/variables`;

    const savedFlowableFormResponse = () => {
        return request(getSavedFlowableForm);
    };

    return save(saveFlowableFormURL, flowableFormData)
        .then(async response => {
            if (response?.exception) {
                setError("Something went wrong");
                throw new Error();
            }
            const savedFlowableFormResponses = await savedFlowableFormResponse();

            return savedFlowableFormResponses;
        })
        .catch(async error => {
            const savedFlowableFormResponses = await savedFlowableFormResponse();

            if (savedFlowableFormResponses?.[taskDictionary?.defaultForm?.REQUEST_KEY || "I would like to..."]) {
                return savedFlowableFormResponses;
            }

            let msg = isNonEmptyString(error)
                ? error
                : error.message || "Something went wrong";
            setError(msg);
            throw new Error();
        })
        .finally(() => {
            setIsFetching(false, { isRefresh: false });
        });
};

const fetchFlowableData = (url, options) => {
    let finalData;
    let finalUrl;

    if (url.includes("search")) {
        let executeURL;
        let finalSearchValue;
        const convertSearch = searchProperty => {
            const searchTerms = convertToSearchTerms({ [searchProperty] : url.split("search=")?.[1] });
            finalSearchValue = encodeSearchTerms(searchTerms);
            executeURL = new URL(`${window.location.origin}${url}`);
        };
        
        if (url.includes("person")) {
            convertSearch("fullName");
            executeURL.searchParams.delete("search");
            finalUrl = `${executeURL?.href}?search=${finalSearchValue}`;
        } else if (url.includes("organization")) {
            convertSearch("name");
            executeURL.searchParams.delete("search");
            finalUrl = `${executeURL?.href}?search=${finalSearchValue}`;
        }
    } else {
        finalUrl = url;
    }

    return executeRequest(finalUrl, options).then(data => {
        if (url.includes("search")) {
            if (url.includes("person")) {
                finalData =  data?._embedded?.persons?.map(data => {
                    return { ...data, name: data?.fullName };
                });
            } else if (url.includes("organization")) {
                finalData = data?._embedded?.organizations;
            }
        } else {
            if (url.includes("person")) {
                finalData = { ...data, name: data?.fullName };
            } else if (url.includes("organization")) {
                finalData = data;
            }
        }

        return {
                text: () => Promise.resolve(JSON.stringify(finalData)),
                json: () => Promise.resolve(finalData),
        };
    });
};

export {
    setIsFetching,
    setError,
    setContent,
    getDefaultForm,
    saveFlowableFormData,
    updateTaskData,
    fetchFlowableData
};
