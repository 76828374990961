import { notify } from "store/actions/notifications";
import { getStore } from "utils/store";

const CONTENT_TYPE_HEADER = "Content-Type";
const ContentTypes = {
	JSON: "json",
	TEXT: "text/plain"
};

export const getTokenSilently = () => {
	return getStore().then(store => {
		const token = store.getState().shell?.cayuseHeader?.authenticationToken;

		return token ? Promise.resolve(token) : Promise.reject(new Error("No Token"));
	})
};

export const request = async url => {
	return executeRequest(url);
};

export const save = async (url, payload) => {
	return executeRequest(url, {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json"
		}
	});
};

export const put = async (url, payload) => {
	return executeRequest(url, {
		method: "PUT",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json"
		}
	});
};

export const patch = async (url, payload) => {
	return executeRequest(url, {
		method: "PATCH",
		body: JSON.stringify(payload),
		headers: {
			"Content-Type": "application/json"
		}
	});
};

export const executeRequest = async (url, options = {}) => {

	return new Promise((resolve, reject) => {
		const doFetch = token => {

			const allOptions = {
				...options,
				headers: {
					...(options.headers || {}),
					Authorization: `Bearer ${token}`,
					"X-IDP-New-Login": true
				}
			};

			return fetch(url, allOptions)
				.then(async response => {
					if (response.status === 401) {
						notify({ type: "login" });
					} else if (response.status === 403) {
						notify({ message: "You aren't permitted to do that", type: "error" });
					} else if (!response.ok) {
						notify({ type: "error" });
					}

					const contentType = response?.headers?.get(CONTENT_TYPE_HEADER);
					if (contentType) {
						if (contentType.indexOf(ContentTypes.JSON) !== -1) {
							resolve(response.json());
						} else if (contentType.indexOf(ContentTypes.TEXT) !== -1) {
							resolve(response.text());
						}
					} else {
						resolve(null);
					}
				});
		};

		getStore().then(store => {
			//todo: move to own redux spot
			const token = store.getState().shell?.cayuseHeader?.authenticationToken;
			if (token) {
				doFetch(token).catch(error => {
					notify({ type: "error" });
					reject(error);
				});
			} else {
				reject(new Error('no token'));
			}
		});

	});
};

export default {
	request,
	save,
	put,
	patch,
	executeRequest
};
