import React from "react";
import { save, request, put } from "utils/proxy";
import store from "utils/store";
import { tasks as constants } from "store/constants";
import {
    isNonEmptyArray,
    isNonEmptyObject,
    encodeSearchTerms,
    isNonEmptyString,
    storeProposalActiveColumns,
    parseJSON,
    getKeysFromColumnHeaders
} from "utils";
import { actionFactory as feedActionFactory } from "store/factories/feed";
import { actionFactory as fetchableActionFactory } from "store/factories/fetchable";
import { FLOWABLE_BASE_URL } from "globals/constants";
import Pager from "components/Pager";
import moment from "moment";
require("moment/min/locales.min");


const { setIsFetching, setError, setContent } = fetchableActionFactory(constants);

const { setPaging, setSort } = feedActionFactory(constants);

const dispatch = store.dispatch;

const setFilters = (filters) => dispatch({ type: constants.SET_FILTERS, filters });

const findTasks = ({ userAccountId, pageLimit, pageOffset, searchTerms, isRefresh = false, createdByMe, showAll }) => {

    const timezone = store.getState().shell?.content?.tenant?.timezone || "UTC";
    const sortColumn = store.getState().tasks?.content?.sort?.column;
    const sortDirection = store.getState().tasks?.content?.sort?.direction;
    const dictionary = store.getState().dictionary?.tasks?.taskTypes || {};

    const lookupTypeName = (taskType) => {
        return dictionary[taskType] || "Other";
    };

    if (pageLimit === undefined) {
        throw Error("page limit cannot be null");
    } else if (pageOffset === undefined) {
        throw Error("page offset cannot be null");
    }

    const params = {
        includeTaskLocalVariables: true,
        includeProcessVariables: true,
        sort: sortColumn,
        order: sortDirection.slice(0, -6), // strip off "ending" to "asc" or "desc" for Flowable
        start: pageOffset * pageLimit,
        size: pageLimit,
        taskVariables: [
            {
                name: "category",
                value: "CAYUSE_%",
                operation: "like",
                type: "string"
            }
        ]
    };

    const urlParams = new URLSearchParams();

    if (createdByMe) {
        params.owner = userAccountId; // Assign userAccountId to owner if createdByMe is true
    } else {
        params.assignee = userAccountId; // Assign userAccountId to assignee if createdByMe is false
    }
    
    if (!showAll) {
        params.status = "Open"; // Set status to "Open" when showAll is false
        params.taskVariables.push({
            name: "status",
            value: "Open",
            operation: "equals",
            type: "string"
        });
    }

    if(searchTerms[0]?.val){
        params.name = searchTerms[0]?.val.replace('TASK: ', '');
    }

    const paramsArray = Object.entries(params)
    .filter(([_, value]) => value !== undefined) // Filter out parameters with undefined values
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`); // Convert each parameter key-value pair to a URL-encoded string

    const url = `${FLOWABLE_BASE_URL}/cayuse-api/query/tasks/admin-platform-my-tasks/nested?${paramsArray.join("&")}&${urlParams.toString()}`;
    // Construct the URL by joining the paramsArray with "&" as the delimiter, and append the URL parameters from urlParams using the toString() method

    
    setIsFetching(true, { isRefresh });
    setError("");

    return request(url, params)
        .then(data => {

            const totalItems = data?.total;
            const taskFeedItems = data?.data;
            const hasTaskFeedItems = isNonEmptyArray(taskFeedItems);

            const mappedColumnValues = taskFeedItems.map(taskFeedItem => {

                //map api values to the column structure
                return {
                    id: taskFeedItem.id,
                    assignee: taskFeedItem.assignee,
                    task: taskFeedItem.name,
                    category: taskFeedItem.category,
                    owner: taskFeedItem.owner,
                    "task-type" : lookupTypeName(taskFeedItem.category),
                    createdOn : moment(taskFeedItem.createTime).tz(timezone)
                                            .format("L"),
                    dueOn : taskFeedItem.dueDate == null ? "" : moment(taskFeedItem.dueDate).tz("UTC")
                                        .format("L"),
                    dueOnTimestamp: taskFeedItem.dueDate,
                    from : taskFeedItem.variables?.assignedFrom,
                    to : taskFeedItem.variables?.assignee,
                    status : taskFeedItem.variables?.status,
                    url : taskFeedItem.variables?.url,
                    description: taskFeedItem.description,
                    completedBy : taskFeedItem.variables?.completedBy,
                    lastUpdated : moment(taskFeedItem.variables?.lastUpdated).tz(timezone).format("L"),
                    toDisplay: (userAccountId === taskFeedItem.assignee || (taskFeedItem.category === 'CAYUSE_SP_SYSTEM' && taskFeedItem.assignee == null)) ? "Me" : taskFeedItem.variables?.assignee,
                    fromDisplay : userAccountId === taskFeedItem.owner ? "Me" : taskFeedItem.variables?.assignedFrom
                };
                
            });

            const searchItems = hasTaskFeedItems ? formatSearchItems(mappedColumnValues) : [];

            setContent({ items:mappedColumnValues, totalItems, searchItems });
        })
        .catch(error => {
            let msg = isNonEmptyString(error)
                ? error
                : error.message || "Something went wrong";

            setError(msg);
        })
        .finally(() => {
            setIsFetching(false, { isRefresh: false });
        });
};

const setSearch = search => dispatch({ type: constants.SET_SEARCH, search });

const formatSearchItems = items => { // todo: this should likely live elsewhere for re-use
    const searchItems = [];
    {
        const mapItems = items.map(pageItem => {
            return {
                key: pageItem.task,
                value: JSON.stringify({ key: "task", val: pageItem.task }),
                text: pageItem.task
            };
        });

        const map = new Map();
        for (const item of mapItems) {
            const itemPair = `${item.key}:${item.val}`;
            if (!map.has(itemPair)) {
                map.set(itemPair, true);
                searchItems.push(item);
            }
        }
    }

    return searchItems;
};


const setTaskSearchBar = search => {
    const searchValue = isNonEmptyString(search) ? parseJSON(search) : search;

    setSearch(searchValue);
};

const refreshTasks = (userAccountId, createdByMe, showAll) => {
    const {
        tasks: {
            content: {
                search: searchTerms,
                paging: {
                    pageNumber,
                    pageLimit
                }
            }
        }
    } = store.getState();

    const pageOffset = Pager.pageOffsetByPageNumber(pageNumber);

    findTasks({ userAccountId, pageLimit, pageOffset, searchTerms, isRefresh: true, createdByMe, showAll });
};

const getTaskVariables = taskId => {
    setError("");
    
    const url = `${FLOWABLE_BASE_URL}/platform-api/tasks/${taskId}/variables`;

    setContent({ taskVariable: {} });

    request(url).then(response => {
        setContent({ taskVariable: response });
    }).catch(error => {
        let msg = isNonEmptyString(error)
                ? error
                : error.message || "Something went wrong";

            setError(msg);
    }).finally(() => {
        setIsFetching(false, { isRefresh: false }); 
    });
};

const createTask = (taskPayload) => {
    return save(`${FLOWABLE_BASE_URL}/cayuse-tasks`, taskPayload);
};

const editTask = (initialTaskId, taskPayload, requestTabData) => {
    return put(`${FLOWABLE_BASE_URL}/cayuse-tasks/${initialTaskId}`, taskPayload)
    .then(() => {
        if(requestTabData) {
            return save(`${FLOWABLE_BASE_URL}/platform-api/tasks/${initialTaskId}/save-form`, requestTabData);
        }
    });
};

export {
    setIsFetching,
    setError,
    setContent,
    findTasks,
    setTaskSearchBar,
    createTask,
    editTask,
    refreshTasks,
    getTaskVariables,
    setFilters,
    setPaging,
    setSort
};
