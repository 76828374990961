import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import { crunchClasses, isNonEmptyString } from "utils";
import Button from "components/Button";

import styles from "./styles.css";


const IconButton = ({
	children,
	icon,
	labelPosition,
	className: incomingClassName,
	...rest
}) => {
	const classNames = crunchClasses({
		[styles.iconButton]: true,
		[styles.labelLeft]: children && labelPosition === "left",
		[styles.labelRight]: children && labelPosition !== "left",
		[styles.labelNo]: !children,
		[incomingClassName]: isNonEmptyString(incomingClassName)
	});

	return (
		<Button className={classNames} {...rest} >
			{children}
			{isNonEmptyString(icon) && <Icon className={styles.icon} name={icon} aria-hidden="true" />}
		</Button>
	);
};

IconButton.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	icon: PropTypes.string,
	labelPosition: PropTypes.oneOf([ "left", "right" ])
};

export default IconButton;
