
const d = { // todo: move to constants
	SET_DICTIONARY: "DICTIONARY_SET"
};

const initialState = {};

export const dictionary = (state = initialState, action) => {
	if (action.type === d.SET_DICTIONARY) {
		return action.dictionary;
	}

	return state;
};
