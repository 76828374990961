import { task as constants } from "store/constants";
import {
  reduceIsFetching,
  reduceSetError,
  reduceShallowContent,
  initialState as initialFetchableState
} from "store/factories/fetchable";

const initialState = {
  ...initialFetchableState,
  content: {
    tasks: {
      TSK123: {
        description: "initial task description",
        variables: [
          {
            name: "assigneeName",
            value: "initial assignee name"
          },
          {
            name: "ownerName",
            value: "initial owner name"
          }
        ]
      }
    }
  }
};

export function task(state = initialState, action) {
  switch (action.type) {
    case constants.IS_FETCHING:
      return reduceIsFetching(state, action);
    case constants.SET_ERROR:
      return reduceSetError(state, action);
    case constants.SET_CONTENT:
      return reduceShallowContent(state, action);
    case constants.RESET:
      return initialState;
    default:
      return state;
  }
}
