import { notification as constants } from "store/constants";

const initialState = {};

export const notifications = (state = initialState, action) => {
  if (action.type === constants.NOTIFY) {
    return action.notification;
  }

  return state;
};
