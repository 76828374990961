import {
	STORAGE_SHELL_CONTENT,
	PRODUCT_KEY
} from "globals/constants";
import { isNonEmptyString } from "utils";

export const storeShellContent = content => {
	content.cayuseHeader = {
		product: PRODUCT_KEY,
		host: null,
		userId: null,
		authenticationToken: null
	};

	const json = JSON.stringify(content);

	if (isNonEmptyString(json)) {
		sessionStorage.setItem(STORAGE_SHELL_CONTENT, json);
	}
};

export const storeItem = (key, item, storage = sessionStorage) => {
	const json = isNonEmptyString(item)
		? item
		: JSON.stringify(item);

	if (isNonEmptyString(json)) {
		storage.setItem(key, json);
	}
};

export const getStoredItem = (key, storage = sessionStorage) => {
	const json = storage.getItem(key);

	return isNonEmptyString(json)
		? JSON.parse(json) || undefined
		: undefined;
};

export const getStoredShellContent = () => {
	const json = sessionStorage.getItem(STORAGE_SHELL_CONTENT);

	return isNonEmptyString(json)
		? JSON.parse(json) || undefined
		: undefined;
};
